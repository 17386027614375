/* CustomPagination */
.custom_items_pagination_main {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}

.custom_items_pagination_main .custom_items_pagination {
  border-radius: 6px;
  background: #fff;
  padding: 10px;
}

.custom_items_pagination .custom_items_pagination_prev_btn,
.custom_items_pagination .custom_items_pagination_next_btn {
  /* padding: 10px; */
  border: none;
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.custom_items_pagination .custom_items_pagination_item {
  color: #888;
  font-family: "Inter";
  font-size: 14px;
  height: 30px;
  width: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  margin: 0 3px;
  background: transparent;
  cursor: pointer;
}

.custom_items_pagination .custom_items_pagination_item.pagination_item_active {
  border-radius: 4px;
  background: #3968ed;
  color: white;
}

/* CustomPagination */
.custom_items_pagination_main-arrows {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 10px 0; */
}

.custom_items_pagination_main-arrows .custom_items_pagination-arrows {
  border-radius: 6px;
  /* background: #fff; */
  /* padding: 10px; */
}

.custom_items_pagination-arrows .custom_items_pagination_prev_btn-arrows,
.custom_items_pagination-arrows .custom_items_pagination_next_btn-arrows {
  /* padding: 10px; */
  border: none;
  height: 30px;
  width: 30px;
  cursor: pointer;
  font-size: larger;
}

.custom_items_pagination_prev_btn-arrows_dis {
  cursor: not-allowed;
}

.custom_items_pagination-arrows .custom_items_pagination_item-arrows {
  color: #888;
  font-family: "Inter";
  font-size: 14px;
  height: 30px;
  width: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  margin: 0 3px;
  background: transparent;
  cursor: pointer;
}

.custom_items_pagination-arrows
  .custom_items_pagination_item-arrows.pagination_item_active-arrows {
  border-radius: 4px;
  background: #3968ed;
  color: white;
}
