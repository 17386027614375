.multi-select-search-dropdown {
    position: relative;
    width: 100%;
    padding: 2px 1px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .multi-select-search-dropdown-selected-option {
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
  }
  .multi-select-search-dropdown-dropdown-content {
    border: 1px solid #ccc;
    border-radius: 4px;
    position: absolute;
    width: 100%;
    top: 100%;
    background-color: #fff;
  }
  
  .multi-select-search-dropdown-icon {
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out;
  }
  
  .multi-select-search-dropdown-icon-close {
    transform: rotate(0deg);
    transition: transform 0.3s ease-in-out;
  }
  .multi-select-search-dropdown-input-container {
    padding: 5px 10px;
    width: 100%;
    border-bottom: 1px solid #ccc;
  }
  .multi-select-search-dropdown-input {
    border: 1px solid #ccc;
    width: 100%;
    padding: 5px 10px;
    border-radius: 4px;
  }
  .multi-select-search-dropdown-button-container {
    margin-top: 10px;
    display: flex;
    gap: 10px;
  }
  .multi-select-search-dropdown-button-container > button {
    background-color: transparent;
    border: none;
    color: #3968ed;
    cursor: pointer;
    font-size: 12px;
  }
  .multi-select-search-dropdown-button-container > button:hover {
    text-decoration: underline;
  }
  .multi-select-search-dropdown-input:focus {
    outline: none;
  }
  .multi-select-search-dropdown-options-list-container {
    padding: 5px 0;
    min-height: 20px;
    max-height: 150px;
    overflow-y: auto;
  }
  .multi-select-search-dropdown-options-list {
    list-style: none;
    font-size: 14px;
    
  }
  .multi-select-search-checkbox {
    margin-right: 10px;
    cursor: pointer;
  }
 
  .multi-select-search-label {
    cursor: pointer;
  }
  .multi-select-search-dropdown-option-list-items.focused {
    background-color: #e0e0e0;
  }
  
  .multi-select-search-dropdown-option-list-items{
    display: flex;
    align-items: center;
    padding: 2px 5px;
  }
  .multi-select-search-dropdown-option-list-items:hover{
    background-color: #e0e0e0;
  }

  .multi-select-dropdown-highlight {
    color: red; 
  }
 