.chatbot_container {
    background-color: #3968ED;
    width: 40px;
    height: 120px;
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 5px 5px 0px;
    cursor: pointer;
}

.chatbot_text {
    color: white;
    transform: rotate(270deg);
    width: 100px;
    white-space: nowrap;
    font-size: 14px;
    font-family: Inter;
    letter-spacing: 1px;
}

.chatbot_popup {
    position: relative;
    width: 100%;
    height: 100%;
}

.chatbot_popup_child {
    position: absolute;
    left: -10px;
    top: 50%;
    transform: translateY(-50%);
    height: fit-content;
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    background: white;
}

.chatbot_popup_child a {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    text-decoration: none;
    gap: 5px;
    white-space: nowrap;
}

.chatbot_popup_child a span {
    color: #000000;
}

.chatbot_popup_child a div {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #fff;
    border-radius: 50%;
    margin-right: 10px;
}

.whatsappPopupIconContainer {
    position: relative;
}

.whatsappPopupLinksContainer {
    position: absolute;
    top: 110%;
    width: 200px;
   
}

.whatsappPopupLinksContainerChild {
    background-color: white;
    padding: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 4px;
}

/* .whatsappPopupLinksContainerChild {
    background-color: white;
    padding: 10px;
} */

.whatsappPopupIconContainer .whatsappPopupIconContainer_text {
    font-family: "Inter";
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    text-align: left;
    color: #3968ED;
}