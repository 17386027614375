.room_adults_child_input_drop_down {
  position: relative;

  .room_adults_child_input_box {
    display: flex;
    height: 36px;
    padding: 10px 5px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 4px;
    border: 0.5px solid gray;
    cursor: pointer;
    div {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      .divider {
        font-weight: 900;
        font-size: 20px;
      }
    }
  }
  .child_age_input {
    width: 40px;
    padding: 4px;
    height: 20px;
    border-radius: 4px;
    border: 1px solid #333;
    &:focus {
      outline: none;
    }
  }
}
