.create_reservation_vertical_inputs_container input {
    width: 45px;
    height: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    outline: none;
    padding: 0px 5px;
    margin-bottom: 8px;
  }
  .flex{
    display: flex;
  }
  .gap_7{
    gap: 7px;
  }
  
  .create_reservation_vertical_inputs_container {
    width: 52px;
  }
  .Create_booking_label{
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    cursor: pointer;
}
.Create_booking_label_small{
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
  color: #333333;
}
.adlut_box_input{
  width: 45px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

@keyframes border-animation {
  0% {
    opacity: .8; /* Start with border hidden */
  }
  50% {
    opacity: 1; /* Fade in border */
  }
  100% {
    opacity: .7; /* Fade out border */
  }
}

.create_booking_error-border {
  animation: border-animation 1s ease infinite; /* Apply animation to border */
}