.accordionContainer {
  width: 100%;
  border-radius: 6px;
  border: 0.5px solid #d6d6d6;
  overflow: hidden;
  .accordionItem {
    border-bottom: 1px solid #e0e0e0;
    &:last-child {
      border-bottom: none;
    }
  }
}
.accordionContainerSeparate {
  width: 100%;
  border-radius: 6px;

  .accordionItem {
    margin: 10px 0;
    border-radius: 6px;
    overflow: hidden;
  }
}


.accordionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: #f7f7f7;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #eaeaea;
  }

  h3 {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    text-align: left;
  }

  .icon {
    transition: transform 0.3s ease;
  }

  .rotate {
    transform: rotate(180deg);
  }
}

.accordionContent {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease, padding 0.3s ease;
  background-color: #fff;
  padding: 0 15px;

  p {
    margin: 10px 0;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    color: #858585;
  }

  &.open {
    max-height: 200px; /* Adjust based on content */
    padding: 15px;
  }
}








//==================================

.accordionContainerOnlyLinks {
  border: none;
  

  .accordionHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    cursor: pointer;
    background-color: transparent;

    &:hover {
      background-color: #eaeaea;
    }

    h3 {
      font-family: "Inter";
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      text-align: left;
    }

    .icon {
      transition: transform 0.3s ease;
    }

    .rotate {
      transform: rotate(180deg);
    }
  }

  .accordionContent {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease, padding 0.3s ease;
    // border-bottom: 1px solid #e0e0e0;
    border-top: 1px solid #e0e0e0;
    padding: 0 15px;
    background-color: transparent;

    p {
      margin: 10px 0;
      font-family: "Inter";
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      text-align: left;
      color: #858585;
    }

    &.open {
      max-height: 200px; /* Adjust based on content */
      padding: 15px;
    }
  }
}
