.moreOptionsButtonContainer {
  position: relative;
  width: fit-content;
  .moreOptionsContainerChild {
    position: absolute;
    top: 110%;
    background-color: #fff;
    box-shadow: 4px 4px 4px 4px rgba(127, 155, 179, 0.5);
    width: 100%;
    border-radius: 4px;
    padding: 5px;
    button {
      width: 100%;
      text-align: center;
      padding: 5px;
      background-color: #fff;
      border: none;
      cursor: pointer;

      &:hover {
        background-color: #d6d6d6;
      }
    }
  }
}
