@import "../../Components//NewCustomComp/settings/globalMixins";
.container {
  position: fixed;
  left: 0;
  top: 0;
  width:100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($color: #000000, $alpha: 0.5);
  z-index: 10000;
  .popupContainer {
    background-color: #fff;
    width: 600px;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    h2 {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 10px;
    }
    .close {
      background-color: transparent;
      border: none;
      font-size: 20px;
      position: absolute;
      right: 15px;
      top: 15px;
      cursor: pointer;
      color: #838383;
    }
    label{
      cursor: pointer;
      width: fit-content;
      input{
        margin-right: 5px;
        cursor: pointer;
        margin-bottom: 10px;
      }
    }
    .child {
      width: 100%;
      max-height: 400px;
      overflow: auto;
      padding-right: 5px;
      margin-bottom: 10px;
      @include scrollbar-styling;

      .generalInfo {
        border-radius: 8px;
        border: 1px solid #ccc;
        padding: 10px;
        &:nth-child(2) {
          margin: 10px 0;
        }
        h3 {
          font-weight: 600;
          font-family: "Inter";
        }
        .details {
          display: flex;
          justify-content: space-between;
          p {
            margin-top: 10px;
          }
        }
      }
    }
  }
}
// button{
//   background-color: transparent;
//   border: none;
//   font-size: 20px;
//   position: absolute;
//   top:10px;
//   right: 20px;
//   cursor: pointer;
//   color: #838383;
// }
.selectTag{
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  &:focus{
    outline: none;
    border: 1px solid #3968ed;
  }
}

