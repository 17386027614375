@import "../settings/_settings";
@import "../settings/globalMixins";

.selectTagContainer {
  width: 100%;

  .small {
    font-size: 12px;
  }

  .medium {
    font-size: 14px;
  }

  .large {
    font-size: 16px;
  }

  .error_text {
    font-family: "Inter";
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    color: $error-color;
    display: block;
    margin-top: 3px;
  }

  .disabled {
    background-color: $disabled-input-bg-color;
    border: 1px solid $primary-btn-disabled-color;
    color: #858585;
    cursor: not-allowed !important;

    &:focus {
      border-color: $primary-btn-disabled-color;
    }
  }

  .label {
    padding-bottom: 3px;

  }

}

.optionsParentContainer {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  outline: none;
  text-transform: capitalize;

  // transition: border-color 0.3s;
  &:focus {
    border-color: $primary-btn-color;
  }

  &.large {
    height: 35px;
    // margin-top: 5px;


  }

  &.small {
    height: 23px;
    // margin-top: 5px;

  }

  &.medium {
    height: 29px;
    // margin-top: 5px;

  }

  svg {
    transition: transform 0.3s ease;
  }
}



.optionsContainer {
  display: none;
  padding: 5px 0;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid #ccc;
  background: white;
  z-index: 1;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  opacity: 1;
  transform: translateY(2px);
  transition: opacity 0.3s ease, transform 0.3s ease;
  max-height: 200px;
  overflow: auto;
  @include scrollbar-styling;

  .option {
    padding: 2.5px 5px;
    display: flex;
    align-items: center;
    gap: 5px;

    button {
      cursor: pointer;
      width: 100%;
      height: 100%;
      border: none;
      text-align: left;
      background-color: transparent;
      // text-transform: capitalize;
    }

    &:hover {
      background-color: #f0f0f0;
    }
  }
}


.svgIcon {
  transition: transform 0.3s ease;
}

.svgIcon.rotated {
  transform: rotate(180deg);
}

.hidden {
  display: none;
}

.checkboxContainerButton {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;

  input[type="checkbox"] {
    cursor: pointer;
  }
}

.multiselectSearchContainer {
  width: 90%;
  height: fit-content;
  margin: 0 auto;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 5px;
  padding: 3px 5px;

  input {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 4px;
    padding-left: 5px;


    &:focus {
      outline: none
    }
  }

  &:focus-within {
    border: 1px solid $primary-btn-color;
  }
}


// // styling done for multiple select with search input
.multiselectSearchInputButtonContainer {
  background-color: #fff;
  border-bottom: 0.4px solid #ccc;
  position: sticky;
  top: 0;

  .multiselectClearAllSelectAllContainer {
    display: flex;
    gap: 5px;
    align-items: center;
    width: 90%;
    margin: 0 auto 5px auto;

    button {
      font-size: 12px;
      color: #3968ED;
      border: none;
      background-color: transparent;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.multiselectSearchFilterOptionContainer {
  max-height: 130px;
  overflow: auto;
  @include scrollbar-styling;
}

//===========================================================================


.selectWithTagsWrapper {
  position: relative;
  width: 100%;

  .selectWithTags {

    -webkit-appearance: none !important;


    &:focus-visible {
      outline: none !important;
    }

    &:focus {
      border-color: $primary-btn-color !important;
    }

    &.large {
      height: 35px !important;
      font-size: 16px !important;
      // margin-top: 5px;


    }

    &.small {
      height: 26px !important;
      font-size: 12px !important;
      // margin-top: 5px;

    }

    &.medium {
      font-size: 14px !important;
      height: 29px !important;
      // margin-top: 5px;

    }
  }

  svg {
    position: absolute;
    right: 5px;
    top: 23%;
    transition: transform ease-in-out 0.2sec;
    transform: rotate(0deg);

  }
  // &:focus-within svg{
  //   transform: rotate(180deg);
  // }

  


}