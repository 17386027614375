.selectdateRange_container {
    /* width: 200px; */
    height: 30px;
    flex-shrink: 0;
    border-radius: 4px;
    border: 0.5px solid #333;
    display: flex;
    padding: 0 10px;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    /* margin: 2rem auto; */
}

.selectdateRange_text {
    color: #888;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

/* .react-datepicker-popper {
    top: 20px;

    left: -15% !important;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    top: 0px;
    left: 36px;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
    top: 0;
    left: 36px;
} */