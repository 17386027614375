// Primary and Secondary Button
$primary-btn-color: #3968ED;
$primary-btn-hover-color: #6A8EF1;
$primary-btn-active-color: #2B59D9;
$primary-btn-disabled-color: #D6D6D6;

// Ghost Button
$ghost-btn-color: #333333;
$ghost-btn-hover-color: #6A8EF1;
$ghost-btn-active-color: #ADADAD;
$ghost-btn-disabled-color: #D6D6D6;

// Input
$disabled-input-bg-color: #F5F5F5;
$error-color: #ED3939;


$color-blue: #4e67ff;

$color-black: #000000;
$light-gray: #F6F6F8;
$color-white: #ffffff;
$color-gray: #2A2A2A;
$ternary-color: #555555;
$color-dark-gray: #444444;
$color-light-gray: #CCCCCC;
$border-color: #A9A9A9;
$contact-icon-color: #888888;
$text-color: #AAAAAA;
$green-color: #4CA655;
$button_disable:#6A8EF1;

$primary-color: #3968ED;
$success-color: #2DB232;
$warning-color: #C99D16;
$critical-color: #E57422;
$danger-color: #ED3939;
$draft-color: #858585;