  .loader_wrap {
    width: 100px;
    height: 100px;
    display: grid;
    place-content: center;
    margin: 0 auto;
  }
  .loader_infinite_scrolling {
    display: inline-block;
    align-items: center;
    justify-content: space-around;
    width: 80px;
    height: 20px;
  }
  
  .loader_infinite_scrolling div {
    margin-left: 5px;
    width: 15px;
    height: 15px;
    background-color: #3968ed;
    border-radius: 50%;
    display: inline-block;
    animation: loader-3-bounce 1.4s infinite ease-in-out both;
  }
  
  .loader_infinite_scrolling div:nth-child(1) { animation-delay: -0.32s; }
  .loader_infinite_scrolling div:nth-child(2) { animation-delay: -0.16s; }
  
  @keyframes loader-3-bounce {
    0%, 80%, 100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1.0);
    }
  }
  
  