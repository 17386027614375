@import "../settings/globalMixins";

.parentContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(3px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    background-color: hsla(0, 0%, 0%, 0.2);
  .container {
    width: 250px;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    h2 {
      font-weight: 600;
      font-size: 18px;
      font-family: "Inter";
      color: #ed3939;
      margin-bottom: 5px;
    }
    p {
      font-size: 12px;
      font-family: "Inter";
      color: #858585;
    }
    .buttonsContainer {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      gap: 10px;
      align-items: center;
      .noBtn {
        border: none;
        background-color: transparent;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        cursor: pointer;
        padding: 5px 10px;
        border-radius: 4px;
      }
      .yesBtn {
        border: none;
        background-color: #ed3939;
        color: #fff;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        cursor: pointer;
        padding: 5px 10px;
        border-radius: 4px;
      }
    }
    .reasonContainer {
      position: relative;
      margin: 10px 0;
      label {
        font-size: 12px;
        font-family: "Inter";
        font-weight: 500;
      }
      textarea {
        margin-top: 5px;
        width: 100%;
        height: 80px;
        font-size: 12px;
        border: 1px solid #ccc;
        border-radius: 4px;
        resize: none;
        padding: 5px;
        @include scrollbar-styling;
        &:focus {
          outline: none;
        }
      }
      .charCount {
        color: #c5c4c4;
        font-size: 12px;
        position: absolute;
        bottom: 5px;
        right: 8px;
      }
    }
  }
}
