.truncatedTextContainer {
    position: relative;
    width: fit-content;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  
  .truncatedTextContainer_text {
    white-space: nowrap;
    overflow: hidden;
    width: 400px;
    text-overflow: ellipsis;
    cursor: pointer;
  }
  
  .truncatedTextContainer_text_tooltip {
    position: absolute;
    display: none;
    opacity: 0;
    transition: opacity 0.1s ease;
    bottom: 22px;
    padding: 5px;
    border-radius: 4px;
    box-shadow: 0px 0px 12px rgba(2, 2, 111, 0.4);
    text-align: left;
    z-index: 98;
    display: block;
    transition: opacity 0.5s ease-in-out;
    background: #fff;
    width: fit-content;
    left: -10px;



  }
  
  .truncatedTextContainer_text:hover + .truncatedTextContainer_text_tooltip {
    display: block;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }
  