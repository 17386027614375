.custom_date_range_picker_new_design_system {
  position: relative;

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    padding: 0.166rem;
    margin: 0;
  }

  .in-range {
    background-color: rgba(57, 105, 237, 0.2); /* Light blue for the range */
    color: #333;
    border-radius: 0;
  }

  .start-range {
    background-color: #0061f2 !important; /* Blue for start date */
    color: white !important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .end-range {
    background-color: #0061f2 !important; /* Blue for end date */
    color: white !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .react-datepicker__day--in-selecting-range {
    background-color: rgba(57, 105, 237, 0.2);
    color: #333;
    border-radius: 0;
  }
  .react-datepicker__day--keyboard-selected{
    background-color: #3968ed !important;
    color: #fff !important;
  }

.react-datepicker__day.start-range {
  border-radius: 4px 0 0 4px;
  

}



}
