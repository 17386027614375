.agent_dashboard_main {
    border-radius: 8px 8px 0px 0px;
    border: 0.5px solid #CCC;
    background: #FFF;
    margin-top: 20px;
}

.agent_dashboard_common_title {
    color: #222;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.agent_dashboard_common_sub_title {
    color: #333;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 5px;
}

.agent_dashboard_main_hotels {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.agent_dashboard_main_hotels .agent_dashboard_main_hotels_left,
.agent_dashboard_main_hotels .agent_dashboard_main_hotels_right {
    display: flex;
    align-items: center;
    gap: 10px;
}

.agent_dashboard_main_hotels .agent_dashboard_main_hotels_left h2 {
    display: flex;
    align-items: center;
    gap: 5px;
}

.agent_dashboard_main_hotels_left p {
    color: #888;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    margin: 8px 0;
    line-height: 16px
}

.agent_dashboard_main_hotels_right .hotel_rates {
    text-align: right;
}

.agent_dashboard_main_hotels_right .hotel_rates p {
    color: #222;
    text-align: right;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px
}

.agent_dashboard_main_hotels_right .hotel_rates p strong {
    color: #3968ED;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
}

.agent_dashboard_main_hotels_right .hotel_rates .hotel_rates_small {
    color: #888;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.show_hotel_rooms {
    border-radius: 0px 0px 8px 8px;
    border: 0.5px solid #CCC;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    background: #FFF;
    margin-top: 20px;
    /* padding: 20px; */
}

.show_hotel_rooms .show_hotel_rooms_top_bar {
    display: flex;
    justify-content: space-between;
    margin: 20px;
}

.show_hotel_rooms .show_hotel_rooms_top_bar h2 .agent_dashboard_common_subtitle {
    color: #888;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.show_hotel_rooms .show_hotel_rooms_top_bar button {
    border: none;
    background: transparent;
    color: #333;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
}

.hotel_rooms .hotel_rooms_image_container {
    height: 100%;
    display: flex;
}


.hotel_rooms_plans_container {
    display: flex;
    flex-direction: column;
    height: 100%;
    /* gap: 20px; */
}

/* .hotel_rooms .hotel_rooms_image_container img {
    height: 100%;
    width: 100%;
    object-fit: cover;
} */

.show_hotel_rooms .show_hotel_rooms_plans {
    padding: 6px 20px;
    transition: .5s;
    border-bottom: 0.5px solid #CCC;
}

.show_hotel_rooms .show_hotel_rooms_plans:hover {
    background-color: #F5F5F5;
}

/* .show_hotel_rooms_plans .show_hotel_rooms_plans_right {
width: 30%;

} */

.show_hotel_rooms_plans .show_hotel_rooms_plans_right .plan_price {
    color: #333;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.show_hotel_rooms_plans .show_hotel_rooms_plans_right .plan_price span {
    color: #888;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.show_hotel_rooms_plans .show_hotel_rooms_plans_right .plan_price strong>span {
    color: #333;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.agent_dashboard_chart_button,
.agent_dashboard_chart_button_rounded {
    color: #666;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: none;
    background: transparent;
    padding: 10px 15px;
    cursor: pointer;
}

.agent_dashboard_chart_button.active {
    color: #3968ED;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-bottom: 2.5px solid #3968ED;
}

.agent_dashboard_chart_button_rounded.active {
    border-radius: 50px;
    background: #3968ED;
    color: white;
    padding: 10px 15px;
    /* display: flex;
    height: 35px;
    padding: 10px 15px;
    justify-content: center;
    align-items: center; */
}

.room_adults_child_input {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
}

.room_adults_child_input_options {
    z-index: 99;
    /* height: 100%; */
    width: 100%;
    position: absolute;
    top: 60px;
    background-color: white;
    color: gray;
    padding: 10px;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.4);
}

.room_adults_child_input_options .optionItem {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.room_adults_child_input_options .optionItem .optionCounter {
    display: flex;
    gap: 10px;
    align-items: center;
    border-radius: 4px;
    border: 0.5px solid #333;
    padding: 5px;
}

.room_adults_child_input_options .optionItem .optionCounter .optionCounterButton {
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
    cursor: pointer;
}


.room_adults_child_input_box {
    display: flex;
    height: 36px;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 4px;
    border: 0.5px solid gray;
}

.adult_room_input {
    border: none;
    width: 30px;
    text-align: center;
}

.room_adults_child_input_options .optionItem .optionText {
    color: #333;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.review_reservation_popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    z-index: 100 !important;
    width: 100vw;
    height: 100vh;
    /* background-color: red; */
    background-color: #0000001f;
    display: flex;
    align-items: center;
    justify-content: center;
}

.room_details_popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    z-index: 100 !important;
    width: 100vw;
    height: 100vh;
    background-color: #0000001f;
    display: flex;
    align-items: center;
    justify-content: center;
}

.room_details_popup .room_details_popup_main {
    width: 540px;
    height: auto;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px;
}

.disabled_button_booking_flow {
    position: relative;
    padding: 10px 20px;
    background-color: #ccc;
    color: #666;
    border: none;
    cursor: not-allowed;
}

.disabled_btn_tooltip {
    visibility: hidden;
    width: 140px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    font-size: 12px;
    position: absolute;
    z-index: 1;
    left: 0;
    opacity: 1;
    transition: opacity 0.3s;
}

.disabled_button_booking_flow:hover .disabled_btn_tooltip {
    visibility: visible;
    opacity: 1;
}