.container {
  background: #fff;
  border-radius: 10px;
  .headingContainer {
    padding: 20px;
    border-bottom: 0.5px solid #ccc;
    h3 {
      font-weight: 700;
      font-size: 16px;
      font-family: "Inter";
    }
  }
  .limitsContainer {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;

    .limitContainerChild {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      border-radius: 4px;
      font-family: "Inter";
      &:first-of-type {
        background-color: #ecf1ff;
      }
      &:last-of-type {
        background-color: #fff5dc;
      }
    }
  }
}
