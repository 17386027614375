.custom_report_table_container {
  display: block;
  border-radius: 8px;
  border: 0.5px solid #9e9d9d;
  border-radius: 8px;
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
  background-color: #fff;
}

.custom_report_table {
  border-collapse: collapse;
  width: 100%;
  text-align: left;

}
.custom_report_table_commonHeading_row th:first-child {
  border-top-left-radius: 8.5px;
}

.custom_report_table_commonHeading_row th:last-child {
  border-top-right-radius: 8.5px;
}

.report_table_head_row_th {
  padding: 5px;
  text-align: left;
  background-color: #d3d3d3;
  width: max-content;
  
 
}

.custom_index_table_body_row_td {
  border: 0.1px solid #9e9d9d;
  padding: 5px;
  border-right: none;
  /* text-align: center; */
  width: max-content;
}

.custom_index_table_body_row_tr td:first-child {
  border-left: none; /* Remove left border from the first <td> in each row */
}

.report_table_head_row_th_common_heading_child {
  padding: 5px;
  border-right: 0.5px solid #9e9d9d;
  text-align: left;
}

.custom_report_table_container::-webkit-scrollbar {
  height: 5px;
  border: none;
}

.custom_report_table_container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
  border: none;
}

.custom_report_table_container::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.custom_report_table_container::-webkit-scrollbar-track {
  border-radius: 10px;
  border: none;
}

/* Responsive Design */
@media screen and (max-width: 800px) {
  .custom_report_table_container {
    overflow-x: auto;
  }
  .custom_report_table {
    min-width: max-content;
    border-bottom: 1px solid #9e9d9d;
  }
}
@media print {
  .repeat-table-headers thead {
    display: table-header-group;
  }
}
