@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;600;700;800;900&display=swap");

*,
body,
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "inter";
}

.App {
  margin: 0;
  padding: 0;
}

:root {
  --bg-color: rgba(221, 221, 221, 1);
  --bg-hover: #3968ed;
  color-scheme: light;
  --p-breakpoints-xs: 0rem;
  --p-breakpoints-sm: 30.625rem;
  --p-breakpoints-md: 48rem;
  --p-breakpoints-lg: 65rem;
  --p-breakpoints-xl: 90rem;
  --p-border-radius-0-experimental: 0rem;
  --p-border-radius-05: 0.125rem;
  --p-border-radius-1: 0.25rem;
  --p-border-radius-2: 0.5rem;
  --p-border-radius-3: 0.75rem;
  --p-border-radius-4: 1rem;
  --p-border-radius-5: 1.25rem;
  --p-border-radius-6: 1.875rem;
  --p-border-radius-full: 624.9375rem;
  --p-border-radius-1_5-experimental: 0.375rem;
  --p-border-width-1: 0.0625rem;
  --p-border-width-2: 0.125rem;
  --p-border-width-3: 0.1875rem;
  --p-border-width-4: 0.25rem;
  --p-border-width-5: 0.3125rem;
  --p-border-width-1-experimental: 0.04125rem;
  --p-border-width-2-experimental: 0.0625rem;
  --p-color-bg-inverse: rgba(31, 33, 36, 1);
  --p-color-bg-inset-strong: rgba(97, 106, 117, 1);
  --p-color-bg-inverse-hover: rgba(97, 106, 117, 1);
  --p-color-bg-inverse-active: rgba(135, 144, 155, 1);
  --p-color-bg-strong-hover: rgba(202, 206, 211, 1);
  --p-color-bg-strong-active: rgba(202, 206, 211, 1);
  --p-color-bg-strong: rgba(221, 224, 228, 1);
  --p-color-bg-subdued-active: rgba(235, 236, 239, 1);
  --p-color-bg-disabled: rgba(235, 236, 239, 1);
  --p-color-bg-interactive-disabled: rgba(235, 236, 239, 1);
  --p-color-bg-app: rgba(241, 242, 244, 1);
  --p-color-bg-app-active: rgba(221, 224, 228, 1);
  --p-color-bg-app-hover: rgba(235, 236, 239, 1);
  --p-color-bg-app-selected: rgba(235, 236, 239, 1);
  --p-color-bg-active: rgba(235, 236, 239, 1);
  --p-color-bg-subdued-hover: rgba(241, 242, 244, 1);
  --p-color-bg-inset: rgba(241, 242, 244, 1);
  --p-color-bg-hover: rgba(241, 242, 244, 1);
  --p-color-bg-subdued: rgba(249, 250, 251, 1);
  --p-color-bg-input: rgba(255, 255, 255, 1);
  --p-color-bg: rgba(255, 255, 255, 1);
  --p-color-bg-primary-active: rgba(12, 59, 47, 1);
  --p-color-bg-primary-hover: rgba(18, 84, 67, 1);
  --p-color-bg-primary: rgba(0, 122, 92, 1);
  --p-color-bg-success-strong: rgba(22, 166, 121, 1);
  --p-color-bg-success: rgba(161, 237, 208, 1);
  --p-color-bg-primary-subdued-active: rgba(192, 242, 221, 1);
  --p-color-bg-success-subdued-active: rgba(192, 242, 221, 1);
  --p-color-bg-success-subdued: rgba(224, 248, 238, 1);
  --p-color-bg-primary-subdued-hover: rgba(224, 248, 238, 1);
  --p-color-bg-success-subdued-hover: rgba(240, 253, 248, 1);
  --p-color-bg-primary-subdued: rgba(240, 253, 248, 1);
  --p-color-bg-primary-subdued-selected: rgba(240, 253, 248, 1);
  --p-color-bg-critical-strong-active: rgba(115, 24, 7, 1);
  --p-color-bg-critical-strong-hover: rgba(159, 32, 10, 1);
  --p-color-bg-critical-strong: rgba(197, 40, 12, 1);
  --p-color-bg-critical-subdued-active: rgba(251, 197, 188, 1);
  --p-color-bg-critical: rgba(251, 197, 188, 1);
  --p-color-bg-critical-subdued: rgba(253, 226, 221, 1);
  --p-color-bg-critical-subdued-hover: rgba(254, 243, 241, 1);
  --p-color-bg-caution-strong: rgba(216, 155, 13, 1);
  --p-color-bg-caution: rgba(248, 217, 144, 1);
  --p-color-bg-caution-subdued-active: rgba(250, 229, 178, 1);
  --p-color-bg-caution-subdued: rgba(252, 240, 212, 1);
  --p-color-bg-caution-subdued-hover: rgba(254, 248, 236, 1);
  --p-color-bg-info-strong: rgba(42, 172, 187, 1);
  --p-color-bg-info-subdued-active: rgba(184, 233, 239, 1);
  --p-color-bg-info: rgba(184, 233, 239, 1);
  --p-color-bg-info-subdued: rgba(222, 245, 247, 1);
  --p-color-bg-info-subdued-hover: rgba(238, 250, 251, 1);
  --p-color-bg-interactive-active: rgba(14, 53, 108, 1);
  --p-color-bg-interactive-hover: rgba(20, 73, 149, 1);
  --p-color-bg-interactive: rgba(36, 99, 188, 1);
  --p-color-bg-interactive-subdued-active: rgba(187, 212, 247, 1);
  --p-color-bg-interactive-subdued-hover: rgba(232, 240, 253, 1);
  --p-color-bg-interactive-subdued: rgba(240, 245, 253, 1);
  --p-color-bg-interactive-selected: rgba(240, 245, 253, 1);
  --p-color-bg-warning: rgba(250, 201, 168, 1);
  --p-color-bg-magic-strong: rgba(121, 69, 227, 1);
  --p-color-bg-magic-hover: rgba(226, 214, 250, 1);
  --p-color-bg-magic-active: rgba(203, 180, 248, 1);
  --p-color-bg-magic: rgba(236, 227, 253, 1);
  --p-color-bg-magic-subdued-hover: rgba(236, 227, 253, 1);
  --p-color-bg-magic-subdued-active: rgba(226, 214, 250, 1);
  --p-color-bg-magic-subdued: rgba(242, 237, 253, 1);
  --p-color-border-input-hover: rgba(97, 106, 117, 1);
  --p-color-border-inverse: rgba(97, 106, 117, 1);
  --p-color-border-strong-hover: rgba(135, 144, 155, 1);
  --p-color-border-input: rgba(171, 177, 186, 1);
  --p-color-border-hover: rgba(171, 177, 186, 1);
  --p-color-border-strong: rgba(171, 177, 186, 1);
  --p-color-border: rgba(202, 206, 211, 1);
  --p-color-border-disabled: rgba(221, 224, 228, 1);
  --p-color-border-subdued: rgba(221, 224, 228, 1);
  --p-color-border-interactive-disabled: rgba(221, 224, 228, 1);
  --p-color-border-primary: rgba(0, 122, 92, 1);
  --p-color-border-success: rgba(22, 166, 121, 1);
  --p-color-border-success-subdued: rgba(80, 220, 169, 1);
  --p-color-border-critical-active: rgba(67, 14, 4, 1);
  --p-color-border-critical-hover: rgba(115, 24, 7, 1);
  --p-color-border-critical: rgba(197, 40, 12, 1);
  --p-color-border-critical-subdued: rgba(245, 107, 82, 1);
  --p-color-border-caution: rgba(216, 155, 13, 1);
  --p-color-border-caution-subdued: rgba(245, 196, 82, 1);
  --p-color-border-info: rgba(59, 195, 211, 1);
  --p-color-border-info-subdued: rgba(109, 211, 222, 1);
  --p-color-border-interactive-active: rgba(14, 53, 108, 1);
  --p-color-border-interactive-hover: rgba(20, 73, 149, 1);
  --p-color-border-interactive: rgba(62, 125, 213, 1);
  --p-color-border-interactive-focus: rgba(62, 125, 213, 1);
  --p-color-border-interactive-subdued: rgba(187, 212, 247, 1);
  --p-color-border-magic-strong: rgba(121, 69, 227, 1);
  --p-color-border-magic: rgba(173, 139, 241, 1);
  --p-color-icon-hover: rgba(31, 33, 36, 1);
  --p-color-icon: rgba(97, 106, 117, 1);
  --p-color-icon-active: rgba(31, 33, 36, 1);
  --p-color-icon-subdued: rgba(135, 144, 155, 1);
  --p-color-icon-disabled: rgba(171, 177, 186, 1);
  --p-color-icon-interactive-disabled: rgba(171, 177, 186, 1);
  --p-color-icon-inverse: rgba(221, 224, 228, 1);
  --p-color-icon-on-color: rgba(255, 255, 255, 1);
  --p-color-icon-primary: rgba(0, 122, 92, 1);
  --p-color-icon-success: rgba(22, 166, 121, 1);
  --p-color-icon-critical: rgba(197, 40, 12, 1);
  --p-color-icon-caution: rgba(183, 126, 11, 1);
  --p-color-icon-info: rgba(42, 172, 187, 1);
  --p-color-icon-warning: rgba(242, 117, 34, 1);
  --p-color-icon-interactive-active: rgba(14, 53, 108, 1);
  --p-color-icon-interactive-hover: rgba(20, 73, 149, 1);
  --p-color-icon-interactive: rgba(36, 99, 188, 1);
  --p-color-icon-interactive-inverse: rgba(102, 153, 225, 1);
  --p-color-icon-magic: rgba(121, 69, 227, 1);
  --p-color-text: rgba(31, 33, 36, 1);
  --p-color-text-subdued: rgba(97, 106, 117, 1);
  --p-color-text-disabled: rgba(135, 144, 155, 1);
  --p-color-text-interactive-disabled: rgba(135, 144, 155, 1);
  --p-color-text-inverse-subdued: rgba(171, 177, 186, 1);
  --p-color-text-inverse: rgba(241, 242, 244, 1);
  --p-color-text-on-color: rgba(255, 255, 255, 1);
  --p-color-text-success-strong: rgba(12, 59, 47, 1);
  --p-color-text-success: rgba(0, 122, 92, 1);
  --p-color-text-primary: rgba(0, 122, 92, 1);
  --p-color-text-primary-hover: rgba(18, 84, 67, 1);
  --p-color-text-critical-strong: rgba(67, 14, 4, 1);
  --p-color-text-critical-active: rgba(115, 24, 7, 1);
  --p-color-text-critical: rgba(197, 40, 12, 1);
  --p-color-text-caution-strong: rgba(77, 46, 5, 1);
  --p-color-text-caution: rgba(135, 92, 8, 1);
  --p-color-text-info-strong: rgba(16, 65, 71, 1);
  --p-color-text-info: rgba(32, 130, 141, 1);
  --p-color-text-warning-strong: rgba(77, 36, 5, 1);
  --p-color-text-interactive-active: rgba(14, 53, 108, 1);
  --p-color-text-interactive-hover: rgba(20, 73, 149, 1);
  --p-color-text-interactive: rgba(36, 99, 188, 1);
  --p-color-text-interactive-inverse: rgba(102, 153, 225, 1);
  --p-color-text-magic-strong: rgba(49, 13, 120, 1);
  --p-color-text-magic: rgba(90, 36, 205, 1);
  --p-color-bg-backdrop-experimental: rgba(26, 26, 26, 0.75);
  --p-color-bg-primary-disabled-experimental: rgba(212, 212, 212, 1);
  --p-color-bg-secondary-experimental: rgba(243, 243, 243, 1);
  --p-color-bg-secondary-disabled-experimental: rgba(247, 247, 247, 1);
  --p-color-bg-input-hover-experimental: rgba(250, 250, 250, 1);
  --p-color-border-input-active-experimental: rgba(26, 26, 26, 1);
  --p-color-border-critical-strong-experimental: rgba(142, 31, 11, 1);
  --p-color-bg-input-active-experimental: rgba(247, 247, 247, 1);
  --p-color-bg-transparent-experimental: rgba(26, 26, 26, 0);
  --p-color-bg-transparent-subdued-experimental: rgba(26, 26, 26, 0.05);
  --p-color-bg-transparent-hover-experimental: rgba(26, 26, 26, 0.05);
  --p-color-bg-transparent-active-experimental: rgba(26, 26, 26, 0.07);
  --p-color-bg-inverse-transparent-hover-experimental: rgba(255, 255, 255, 0.1);
  --p-color-bg-inverse-transparent-active-experimental: rgba(255,
      255,
      255,
      0.2);
  --p-color-bg-success-strong-hover-experimental: rgba(19, 111, 69, 1);
  --p-color-bg-success-strong-active-experimental: rgba(12, 81, 50, 1);
  --p-color-bg-warning-subdued-experimental: rgba(255, 241, 227, 1);
  --p-color-bg-warning-strong-experimental: rgba(255, 184, 0, 1);
  --p-color-text-warning-experimental: rgba(65, 45, 0, 1);
  --p-color-text-critical-hover-experimental: rgba(95, 21, 7, 1);
  --p-color-icon-info-strong-experimental: rgba(0, 82, 124, 1);
  --p-color-icon-warning-strong-experimental: rgba(124, 88, 0, 1);
  --p-color-icon-success-strong-experimental: rgba(12, 81, 50, 1);
  --p-color-icon-critical-strong-experimental: rgba(142, 31, 11, 1);
  --p-color-icon-critical-strong-hover-experimental: rgba(95, 21, 7, 1);
  --p-color-icon-critical-strong-active-experimental: rgba(47, 10, 4, 1);
  --p-color-avatar-background-experimental: rgba(181, 181, 181, 1);
  --p-color-avatar-color-experimental: rgba(255, 255, 255, 1);
  --p-color-avatar-style-one-background-experimental: rgba(251, 207, 251, 1);
  --p-color-avatar-style-one-color-experimental: rgba(121, 26, 121, 1);
  --p-color-avatar-style-two-background-experimental: rgba(56, 250, 163, 1);
  --p-color-avatar-style-two-color-experimental: rgba(12, 81, 50, 1);
  --p-color-avatar-style-three-background-experimental: rgba(146, 237, 222, 1);
  --p-color-avatar-style-three-color-experimental: rgba(9, 83, 70, 1);
  --p-color-avatar-style-four-background-experimental: rgba(192, 225, 255, 1);
  --p-color-avatar-style-four-color-experimental: rgba(0, 82, 124, 1);
  --p-color-avatar-style-five-background-experimental: rgba(254, 209, 221, 1);
  --p-color-avatar-style-five-color-experimental: rgba(141, 4, 72, 1);
  --p-font-family-sans: -apple-system, BlinkMacSystemFont, "San Francisco",
    "Segoe UI", Roboto, "Helvetica Neue", sans-serif;
  --p-font-family-sans-experimental: "Inter", -apple-system, BlinkMacSystemFont,
    "San Francisco", "Segoe UI", Roboto, "Helvetica Neue", sans-serif;
  --p-font-family-mono: ui-monospace, SFMono-Regular, "SF Mono", Consolas,
    "Liberation Mono", Menlo, monospace;
  --p-font-size-70-experimental: 0.6875rem;
  --p-font-size-75: 0.75rem;
  --p-font-size-80-experimental: 0.8125rem;
  --p-font-size-100: 0.875rem;
  --p-font-size-200: 1rem;
  --p-font-size-300: 1.25rem;
  --p-font-size-400: 1.5rem;
  --p-font-size-500: 1.75rem;
  --p-font-size-600: 2rem;
  --p-font-size-700: 2.5rem;
  --p-font-weight-regular: 400;
  --p-font-weight-medium: 500;
  --p-font-weight-semibold: 600;
  --p-font-weight-bold: 700;
  --p-font-line-height-075-experimental: 0.75rem;
  --p-font-line-height-1: 1rem;
  --p-font-line-height-2: 1.25rem;
  --p-font-line-height-3: 1.5rem;
  --p-font-line-height-4: 1.75rem;
  --p-font-line-height-5: 2rem;
  --p-font-line-height-6: 2.5rem;
  --p-font-line-height-7: 3rem;
  --p-motion-duration-0: 0ms;
  --p-motion-duration-50: 50ms;
  --p-motion-duration-100: 100ms;
  --p-motion-duration-150: 150ms;
  --p-motion-duration-200: 200ms;
  --p-motion-duration-250: 250ms;
  --p-motion-duration-300: 300ms;
  --p-motion-duration-350: 350ms;
  --p-motion-duration-400: 400ms;
  --p-motion-duration-450: 450ms;
  --p-motion-duration-500: 500ms;
  --p-motion-duration-5000: 5000ms;
  --p-motion-ease: cubic-bezier(0.25, 0.1, 0.25, 1);
  --p-motion-ease-in: cubic-bezier(0.42, 0, 1, 1);
  --p-motion-ease-out: cubic-bezier(0, 0, 0.58, 1);
  --p-motion-ease-in-out: cubic-bezier(0.42, 0, 0.58, 1);
  --p-motion-linear: cubic-bezier(0, 0, 1, 1);
  --p-motion-keyframes-bounce: p-motion-keyframes-bounce;
  --p-motion-keyframes-fade-in: p-motion-keyframes-fade-in;
  --p-motion-keyframes-pulse: p-motion-keyframes-pulse;
  --p-motion-keyframes-spin: p-motion-keyframes-spin;
  --p-motion-keyframes-appear-above: p-motion-keyframes-appear-above;
  --p-motion-keyframes-appear-below: p-motion-keyframes-appear-below;
  --p-shadow-inset-lg: inset 0rem 0rem 0.4375rem 0.125rem rgba(31, 33, 36, 0.18);
  --p-shadow-inset-md: inset 0rem 0.125rem 0.25rem rgba(31, 33, 36, 0.32);
  --p-shadow-inset-sm: inset 0rem 0rem 0.1875rem rgba(31, 33, 36, 0.56);
  --p-shadow-none: none;
  --p-shadow-xs: 0rem 0rem 0.125rem rgba(31, 33, 36, 0.24);
  --p-shadow-sm: 0rem 0.0625rem 0.0625rem rgba(31, 33, 36, 0.1);
  --p-shadow-md: 0rem 0.125rem 0.25rem rgba(31, 33, 36, 0.1),
    0rem 0.0625rem 0.375rem rgba(31, 33, 36, 0.05);
  --p-shadow-lg: 0rem 0.25rem 0.75rem rgba(31, 33, 36, 0.2),
    0rem 0.125rem 0.375rem rgba(31, 33, 36, 0.05);
  --p-shadow-xl: 0rem 0.25rem 1.125rem -0.125rem rgba(31, 33, 36, 0.08),
    0rem 0.75rem 1.125rem -0.125rem rgba(31, 33, 36, 0.15);
  --p-shadow-2xl: 0rem 2rem 2rem rgba(31, 33, 36, 0.15),
    0rem 2rem 3.5rem -0.125rem rgba(31, 33, 36, 0.16);
  --p-shadow-bevel-experimental: 0.0625rem 0rem 0rem 0rem rgba(0, 0, 0, 0.13) inset,
    -0.0625rem 0rem 0rem 0rem rgba(0, 0, 0, 0.13) inset,
    0rem -0.0625rem 0rem 0rem rgba(0, 0, 0, 0.17) inset,
    0rem 0.0625rem 0rem 0rem rgba(204, 204, 204, 0.5) inset;
  --p-shadow-card-sm-experimental: 0rem 0.125rem 0rem rgba(0, 0, 0, 0.07),
    0rem 0.0625rem 0rem rgba(0, 0, 0, 0.07), 0.0625rem 0rem 0rem #e3e3e3,
    -0.0625rem 0rem 0rem #e3e3e3, 0rem -0.0625rem 0rem #e3e3e3;
  --p-shadow-card-md-experimental: 0rem 0.1875rem 0.0625rem -0.0625rem rgba(0, 0, 0, 0.07),
    0rem -0.0625rem 0rem 0rem rgba(0, 0, 0, 0.16),
    0.0625rem 0rem 0rem 0rem rgba(227, 227, 227, 1),
    -0.0625rem 0rem 0rem 0rem rgba(227, 227, 227, 1),
    0rem 0.0625rem 0rem 0rem rgba(227, 227, 227, 1);
  --p-shadow-card-lg-experimental: 0rem 0.25rem 0.375rem -0.125rem rgba(0, 0, 0, 0.2),
    inset 0rem -0.0625rem 0rem #d4d4d4, inset -0.0625rem 0rem 0rem #e3e3e3,
    inset 0.0625rem 0rem 0rem #e3e3e3, inset 0rem 0.0625rem 0rem #e3e3e3;
  --p-shadow-button-experimental: inset 0rem -0.0625rem 0rem #b5b5b5,
    inset -0.0625rem 0rem 0rem #e3e3e3, inset 0.0625rem 0rem 0rem #e3e3e3,
    inset 0rem 0.0625rem 0rem #e3e3e3;
  --p-shadow-button-disabled-experimental: inset 0rem 0rem 0rem 0.0625rem rgba(227, 227, 227, 1);
  --p-shadow-button-primary-inset-experimental: 0rem 0.125rem 0rem 0rem #000 inset,
    0.0625rem 0rem 0.0625rem 0rem rgba(0, 0, 0, 0.2) inset,
    -0.0625rem 0rem 0.0625rem 0rem rgba(0, 0, 0, 0.2) inset;
  --p-shadow-button-primary-strong-inset-experimental: 0rem 0.1875rem 0rem 0rem #000 inset;
  --p-shadow-button-primary-strong-hover-experimental: 0rem 0.0625rem 0rem 0rem rgba(255, 255, 255, 0.24) inset,
    0.0625rem 0rem 0rem 0rem rgba(255, 255, 255, 0.16) inset,
    -0.0625rem 0rem 0rem 0rem rgba(255, 255, 255, 0.16) inset,
    0rem -0.09375rem 0rem 0rem rgba(255, 255, 255, 0.07) inset,
    0rem 0rem 0rem 0.03125rem #1a1a1a;
  --p-shadow-button-primary-strong-experimental: 0rem 0.125rem 0rem 0rem rgba(255, 255, 255, 0.2) inset,
    0.125rem 0rem 0rem 0rem rgba(255, 255, 255, 0.2) inset,
    -0.125rem 0rem 0rem 0rem rgba(255, 255, 255, 0.2) inset,
    0rem -0.0625rem 0rem 0.0625rem #000 inset,
    0rem 0.0625rem 0rem 0rem #000 inset;
  --p-shadow-button-primary-experimental: 0rem 0.0625rem 0rem 0rem rgba(255, 255, 255, 0.4) inset,
    0.0625rem 0rem 0rem 0rem rgba(255, 255, 255, 0.2) inset,
    -0.0625rem 0rem 0rem 0rem rgba(255, 255, 255, 0.2) inset,
    0rem -0.09375rem 0rem 0rem rgba(0, 0, 0, 0.25) inset;
  --p-shadow-button-primary-hover-experimental: -0.0625rem 0rem 0rem 0rem rgba(255, 255, 255, 0.2) inset,
    0.0625rem 0rem 0rem 0rem rgba(255, 255, 255, 0.2) inset,
    0rem 0.03125rem 0rem 0rem rgba(0, 0, 0, 0.25),
    0rem -0.09375rem 0rem 0rem rgba(255, 255, 255, 0.07) inset,
    0rem 0.0625rem 0rem 0rem rgba(255, 255, 255, 0.24) inset;
  --p-shadow-button-inset-experimental: 0rem 0.125rem 0rem 0rem rgba(0, 0, 0, 0.6) inset,
    0.0625rem 0rem 0.0625rem 0rem rgba(0, 0, 0, 0.2) inset,
    -0.0625rem 0rem 0.0625rem 0rem rgba(0, 0, 0, 0.2) inset;
  --p-shadow-border-inset-experimental: 0rem 0rem 0rem 0.0625rem rgba(0, 0, 0, 0.08) inset;
  --p-space-0: 0;
  --p-space-025: 0.0625rem;
  --p-space-05: 0.125rem;
  --p-space-1: 0.25rem;
  --p-space-1_5-experimental: 0.375rem;
  --p-space-2: 0.5rem;
  --p-space-3: 0.75rem;
  --p-space-4: 1rem;
  --p-space-5: 1.25rem;
  --p-space-6: 1.5rem;
  --p-space-8: 2rem;
  --p-space-10: 2.5rem;
  --p-space-12: 3rem;
  --p-space-16: 4rem;
  --p-space-20: 5rem;
  --p-space-24: 6rem;
  --p-space-28: 7rem;
  --p-space-32: 8rem;
  --p-z-index-1: 100;
  --p-z-index-2: 400;
  --p-z-index-3: 510;
  --p-z-index-4: 512;
  --p-z-index-5: 513;
  --p-z-index-6: 514;
  --p-z-index-7: 515;
  --p-z-index-8: 516;
  --p-z-index-9: 517;
  --p-z-index-10: 518;
  --p-z-index-11: 519;
  --p-z-index-12: 520;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

a {
  text-decoration: none;
}

/* NAVBAR */

.main_container {
  padding-top: 70px;
  padding-bottom: 70px;
  padding-left: 12px;
  padding-right: 12px;
  margin: 0 auto;
  width: 100%;
  max-width: 98%;
}

.react-datepicker-popper {
  z-index: 99 !important;
}

.bg_white {
  background-color: #fff;
}

.container {
  margin: 0 auto;
  width: 100%;
  max-width: 1300px;
}

.navbar-container {
  background-color: #fff;
  border-bottom: 1px #3333332d solid;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 21;
}

.nav-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  width: 100%;
  padding: 15px;
}

.nav-logo--image {
  height: 30px;
  /* margin-right: 14rem; */
}

.nav-input {
  position: relative;
}

.nav-input--input {
  outline: none;
  width: 500px;
  height: 40px;
  position: relative;
  padding-left: 40px;
  background-color: rgba(221, 221, 221, 1);
  border: 1px rgba(221, 221, 221, 1);
  border-radius: 4px;
}

.nav-search-icon {
  height: 40px;
  width: 40px;
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
  background: rgba(221, 221, 221, 1);
}

.nav-search-icon--icon {
  font-size: 140px;
  height: 40px;
  width: 40px;
}

.nav-user {
  display: flex;
  gap: 8px;
  align-items: center;
}

/* Search Components */

/* Profile Page */
.profile-divs {
  display: flex;
  gap: 1px;
}

/*New Sidebar */
.sidebar {
  width: 250px;
  background: #eff0f2;
  border-right: 2px solid #ddd;
  padding: 20px 20px 20px 0;
  position: fixed;
  height: 100%;
}

.sidebar-item {
  margin-bottom: 5px;
}

.sidebar-item .sidebar-item-list {
  padding: 5px 10px;
  transition: all 0.3s;
  color: #666;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
  display: flex;
  border-left: 3px solid transparent;
  align-items: center;
  gap: 10px;
}

.sidebar-item-list .item-list-icon {
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
}

.sidebar-item-list .item-list-icon svg {
  fill: #666666;
}

.sidebar-item:hover .sidebar-item-list {
  background-color: #e5e5e5;
}

.active .sidebar-item-list {
  color: #3968ed;
  border-left: 3px solid #3968ed;
}

.active .sidebar-item-list .item-list-icon svg {
  fill: #3968ed;
}

.sub-items {
  list-style: none;
  margin-top: 5px;
  padding-left: 55px;
}

.sub-item .sub-item-list {
  color: #888;
  text-decoration: none;
  display: block;
  padding-bottom: 5px;
  transition: color 0.2s;
  font-size: 14px;
}

.sub-item .sub-item-list:hover {
  color: #3968ed;
}

.sub-item.active .sub-item-list {
  color: #3968ed;
}

/* Footer Nav */
.footer_nav {
  background-color: white;
  /* position: fixed;
  bottom: 0;
  left: 0; */
  width: 60%;
  z-index: 20;
}

.footer_nav_bar {
  /* display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-top: 1px #3333332d solid; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  /* border-top: 1px #3333332d solid; */
}



.footer_nav_bar .footer_nav_bar_hotel_options,
.footer_nav_bar .footer_nav_bar_other_menus {
  width: 12%;
}

.footer_nav_bar_menus {
  display: flex;
  align-items: center;
  /* width: 100%; */
  justify-content: center;
}

.footer_nav_bar_menus .footer_nav_bar_item {
  /* padding: 9px 15px; */
  cursor: pointer;
  transition: 0.5s;
  margin: 0 10px;
}

.footer_nav_bar_menu_item {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.footer_nav_bar_menu_item:hover {
  color: blue;
  border-radius: 50%;
  background-color: #ddd;
  cursor: pointer;
}

.footer_nav_bar_menus .footer_nav_bar_item .item_list {
  /* display: flex;
  gap: 8px;
  border-radius: 5px;
  color: #666;
  font-family: "Inter";
  font-size: 14px;
  padding: 9px 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
  align-items: center;
  transition: 0.5s; */
  display: flex;
  gap: 4px;
  border-radius: 5px;
  color: #666;
  font-family: "Inter";
  flex-direction: column;
  font-size: 11px;
  padding: 9px 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
  align-items: center;
  transition: 0.5s;

  width: max-content;
}

/* .footer_nav_bar_menus .footer_nav_bar_item .item_list:hover {
  background-color: #E5E5E5;
} */

.other_menus_item .menus_item_list_item {
  display: flex;
  gap: 8px;
  color: #666;
  font-family: "Inter";
  font-size: 14px;
  padding: 9px 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
  align-items: center;
  transition: 0.5s;

  width: max-content;
}

/* .other_menus_item .menus_item_list_item:hover,
.hotel_options_dropdown_options_child:hover {
  background-color: #E5E5E5;
} */
 .item_list{
  color: #888;
 }

.other_menus_item .menus_item_list_item:hover,
.other_menus_item .menus_item_list_item:hover svg,
.other_menus_item .menus_item_list_item:hover svg path {
  color: #3968ed;
  fill: #3968ed;
  transition: 0.5s;
}

.footer_inner_lists {
  border-bottom: 1px solid #ddd;
  background: #fff;
  padding: 18px 0;
  display: flex;
  position: sticky;
  top: 60px;
  left: 50px;
  z-index: 20;
}

.footer_inner_lists ul .inner_list_item {
  color: #888;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  list-style: none;
  display: inline;
  line-height: normal;
  margin-right: 20px;
  margin: 10px;
  padding-bottom: 10px;
  cursor: pointer;
}

.footer_inner_lists ul .inner_list_item a {
  color: #888;
  text-decoration: none;
}

.footer_inner_lists ul .inner_list_item.active {
  color: #3968ed;
  border-bottom: 2px solid #3968ed;
}

.footer_inner_lists ul .inner_list_item.active a {
  color: #3968ed;
}

.footer_nav_bar_item svg{
  fill : #666666
}

.footer_nav_bar_item.active,
.footer_nav_bar_item.active svg,
.footer_nav_bar_item.active .item_list,
.footer_nav_bar_item:hover svg,
.footer_nav_bar_item:hover .item_list {
  color: #3968ed;
  fill: #3968ed;
  /* background-color: #E5E5E5; */
}

.footer_nav_bar .footer_nav_bar_other_menus {
  /* display: flex; */
  justify-content: flex-end;
  position: relative;
}

/* .footer_nav_bar .footer_nav_bar_menus {
  width: 70%;
} */

* {
  margin: 0;
  padding: 0;
}

/* Edit Page Component */
.edit-profile-page {
  width: 100%;
  padding: 20px 30px;
}

.edit-page-heading {
  margin-top: 30px;
  color: #333;
}

.edit-page-heading h1 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 1rem;
}

/* 
.edit-page-container {
    padding: 23px 40px 0 40px;
} */

.edit-page-user--image {
  width: 65px;
  height: 65px;
  margin: 0 auto;
}

.edit-page-container-child {
  /* text-align: center; */
  background-color: white;
  border-radius: 30px;
  padding-top: 1rem;
  /* margin: 0 50px; */
}

.edit-page-container-user {
  /* text-align: center; */
  width: 700px;
  margin: 0 auto;
}

.edit-page-user--name {
  font-weight: 700;
  margin-top: 5px;
  font-size: 16px;
  text-align: center;
}

.edit-page-user--buttons {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

/* form */

.Parent-form-container {
  padding: 0 5rem;
}

.form-container {
  display: flex;
  gap: 2rem;
  margin-top: 0.3rem;
}

.form-container-div {
  width: 100%;
}

.form-full-container {
  width: 100%;
}

.form-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

/* analyse page */
.analyse-divs {
  display: flex;
  gap: 1px;
  min-height: 100vh;
}

.analyse-page {
  width: 100%;
}

.analyse-calender-arrow {
  cursor: pointer;
  background: white;
  width: 36px;
  height: 36px;
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.34);
}

.analyse-page-calender {
  display: flex;
  justify-content: center;
  align-items: center;
}

.analyse-chart-container {
  margin-top: 1rem;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  gap: 1rem;
}

.analyse-chart-container-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.main-container{
  margin-top:70px;
}

/* Room Type Page */
.room-types-full-container {
  padding: 15px 0;
}

.room-types-full-container-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.room-type-heading {
  color: #333;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.room-types-heading div {
  color: #333;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 25px;
}

.room-type-bottom-container {
  background-color: white;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 20px 25px; */
  /* margin-bottom: 20px; */
}

.room-type-bottom-container-div {
  padding: 13px 15px;
  border-radius: 8px;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 9px -6px rgba(0, 0, 0, 0.75);
  position: fixed;
  bottom: 79px;
  z-index: 100;
  background-color: white;
  /* padding: 13px 15px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-box-shadow: 0px 0px 11px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 11px -6px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 6px -5px rgba(0, 0, 0, 0.75); */
}

.room-type-bottom-container-popup-div {
  /* padding: 13px 15px;
  border-radius: 8px;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 9px -6px rgba(0, 0, 0, 0.75);
  position: fixed;
  bottom: 10px;
  z-index: 100;
  background-color: white; */

  padding: 13px 15px;
  display: flex;
  width: 100%;
  bottom: -65px;
  justify-content: space-between;
  align-items: center;
  -webkit-box-shadow: 0px 0px 11px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 11px -6px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 6px -5px rgba(0, 0, 0, 0.75);
}

.multipleroom-delete-btn-div {
  width: max-content;
  margin: 0 auto;
}

.room-type-bottom-container form {
  display: flex;
  gap: 20px;
}

.room-type-bottom-div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.room-type-bottom-container .room-type-bottom-div label {
  color: #333;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.room-type-bottom-container .room-type-bottom-div input {
  width: 40px;
  height: 30px;
  padding: 10px;
  border-radius: 4px;
}

/* All modal */

/* Delete modal */
.bg-backdrops {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.bg-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  z-index: 100 !important;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.room-type-model-container {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  transition: all 1s;
  /* position: absolute; */
  /* top: 40%;
  left: 0;
  right: 0; */
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 10000000000000;
  width: 300px;
  padding: 20px;
  transition: all 5s ease-in;
  background-color: white;
  -webkit-box-shadow: 0px 0px 11px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 11px -6px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 11px -6px rgba(0, 0, 0, 0.75);
  border-radius: 8px;
}

.room-type-model-container h4 {
  color: #3968ed;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 15px;
}

.room-type-model-container p {
  color: #333;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 25px;
}

.room-type-model-container p.deleteContainer {
  margin-bottom: 20px;
}

.reason-input-button {
  color: #333;
  font-family: Inter;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
}

/* RoomPlan Add Modal */
.room-plan-add-model-container,
.dashboard_checkin_modal_container {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  transition: all 1s;
  position: absolute;
  /* top: 40%; */
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  border-radius: 8px;

  flex-direction: column;
  z-index: 10;
  width: 600px;
  padding: 25px;
  transition: all 5s ease-in;
  background-color: white;
  -webkit-box-shadow: 0px 0px 11px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 11px -6px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 11px -6px rgba(0, 0, 0, 0.75);
}

.dashboard_checkin_modal_box {
  max-width: 400px;
  margin: auto;
}

.dashboard_checkin_modal_close_btn {
  text-align: end;
  cursor: pointer;
}

.dashboard_checkin_modal_content {
  text-align: center;
}

.dashboard_checkin_modal_content .synced_successfully_msg {
  color: #15aa12;
  text-align: center;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.dashboard_checkin_modal_content h4 {
  color: #333;
  text-align: center;
  font-family: "Inter";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
  padding-top: 20px;
}

.dashboard_checkin_modal_content .copylink_custom_input_container {
  margin: 20px 0;
}

.show_manual_search_feild {
  /* width: 280px; */
  margin: 0 auto;
}

.dashboard_checkin_modal_btn {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin: 20px 0;
}

.room-plan-add-model-container h4 {
  color: #333;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 15px;
}

.room-plan-add-model-container p {
  color: #333;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 25px;
}

/* Competitors manage Add target Modal */
.competitors-manage-add-target-modal {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  transition: all 1s;
  position: absolute;
  top: 25%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

  flex-direction: column;
  z-index: 10000000000000;
  width: 700px;
  padding: 25px;
  /* transition: all 5s ease-in; */
  background-color: white;
  -webkit-box-shadow: 0px 0px 11px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 11px -6px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 11px -6px rgba(0, 0, 0, 0.75);
}

.competitors-manage-add-target-modal h4 {
  color: #333;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 15px;
}

.competitors-manage-add-target-modal p {
  color: #333;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 25px;
}

/* Room Rate Plan Edit Modal */
.room-rate-plan-edit-model-container {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  transition: all 1s;
  position: absolute;
  top: 20%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  border-radius: 8px;
  flex-direction: column;
  z-index: 10000000000000;
  width: 700px;
  padding: 25px;
  background-color: white;
  -webkit-box-shadow: 0px 0px 11px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 11px -6px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 11px -6px rgba(0, 0, 0, 0.75);
}

.room-rate-plan-edit-model-container h4 {
  color: #333;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 15px;
}

.room-rate-plan-edit-model-container p {
  color: #333;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 25px;
}

/* Agent Add Modal */
.agent-add-model-container {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  transition: all 1s;
  position: absolute;
  top: 2%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

  flex-direction: column;
  z-index: 10000000000000;
  width: 700px;
  padding: 25px;
  background-color: white;
  -webkit-box-shadow: 0px 0px 11px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 11px -6px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 11px -6px rgba(0, 0, 0, 0.75);
}

.agent-add-model-container h4 {
  color: #333;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 15px;
}

.agent-add-model-container p {
  color: #333;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 25px;
}

/* Agent Add category Modal */
.agent-add-categories-model-container {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  transition: all 1s;
  /* position: absolute; */
  /* top: 25%; */
  /* left: 0; */
  /* right: 0; */
  /* margin-left: auto; */
  /* margin-right: auto; */

  max-height: 75svh;
  /* overflow-y: auto; */

  flex-direction: column;
  z-index: 100;
  width: 700px;
  background-color: white;
  -webkit-box-shadow: 0px 0px 11px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 11px -6px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 11px -6px rgba(0, 0, 0, 0.75);
  border-radius: 8px;
}

.agent-add-categories-model-container h4 {
  color: #333;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 15px;
}

.agent-add-categories-model-container p {
  color: #333;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 25px;
}

/* edit Rooms page  */
.edit-rooms-full-container {
  /* background-color: var(--bg-color); */
  height: 100vh;
}

.edit-rooms-full-container-div {
  /* background-color: var(--bg-color); */
  display: flex;
  justify-content: center;
  gap: 15px;
}

/* forms rooms edit */

.edit-rooms-form-wrapper-container {
  background-color: white;
  border-radius: 30px;
}

.form-container-div-half {
  display: flex;
  gap: 14px;
  margin-bottom: 17px;
  width: 100%;
}

.form-container-div-full {
  margin-bottom: 17px;
}

.Parent-form-container {
  padding: 20px;
}

.form-full-container {
  width: 100%;
}

.form-button-container {
  padding: 2rem;
}

.rooms-edit-left-container-buttons {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 90px;
}

.edit-rooms-full-container-right {
  width: 350px;
}

.competitors-input-file-container {
  padding: 10px 20px;
}

.competitors-input-file-container input::file-selector-button {
  background-color: white;
  width: auto;
  padding: 10px;
  border: 0.5px solid #333;
  border-radius: 4px;
  margin-right: 10px;
}

.competitors-input-file-container input {
  width: auto;
}

.competitors-input-file-container input::file-name {
  color: #333;
}

/* LOGIN PAGE */

.login-screen-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: linear-gradient(45deg, #3968ed 0%, #dae3fc 100%);
}

.login-full-container {
  display: flex;
  width: 400px;
  flex-direction: column;
  height: auto;
  background-color: #fff;
  padding: 25px;
}

.login-full-container .login-logo {
  width: 110px;
  margin-bottom: 30px;
}

.login-full-container .login-text-below-logo h3 {
  color: #222;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 9px;
}

.login-full-container .login-text-below-logo p {
  color: #888;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 20px;
}

.login-full-container .continue-with-email-button {
  margin-bottom: 15px;
  margin-top: 12px;
}

.login-full-container .login-or {
  text-align: center;
  margin-bottom: 15px;
}

.login-full-container .credentials-all-buttons {
  display: flex;
  gap: 10px;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 30px;
  font-size: 14px;
}

.login-full-container .login-privacy-buttons {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  color: #888;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.email-entered {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

/* Upgrade  */
.upgrade-bg-full-container {
  /* background-color: #EFF0F2; */
  /* width: 100%; */
  /* height: 100vh; */
  padding: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.upgrade-full-container {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 460px;
  border-radius: 8px;
  width: 500px;
  padding: 45px 75px;
}

.upgrade-full-container-div {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* padding: 60px 40px; */
  flex-direction: column;
  gap: 20px;
}

/* Upgrade Plans  */
.upgrade-plan-bg-full-container {
  background-color: rgba(221, 221, 221, 1);
  width: 100%;
  height: 100%;
  padding: 20px;
}

.upgrade-plan-full-container {
  background-color: #fff;
  width: 100%;
  border-radius: 8px;
}

.upgrade-plan-full-container-div {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  padding: 40px 0;
}

.upgrade-plan-full-container-div h1 {
  font-family: Inter;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 10px;
}

.upgrade-plan-full-container-div p {
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.upgrade-plan-full-container-div .card {
  width: 270px;
  height: 500px;
  border-radius: 8px;
  border: 0.5px solid #ccc;
  padding: 25px 33px;
  position: relative;
  background-color: #fff;
}

.upgrade-plan-full-container-div .upgrade-plan-button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 35px;
  margin-bottom: 25px;
  padding: 5px;
  border-radius: 6px;
  border: 0.5px solid #ccc;
}

.upgrade-plan-full-container-div .upgrade-plan-button-group button {
  background-color: #fff;
  padding: 10px 15px;
  justify-content: center;
  align-items: center;
  border: 0;
  cursor: pointer;
}

.upgrade-plan-full-container-div .upgrade-plan-button-group button.active {
  background-color: #3968ed;
  color: #fff;
  border-radius: 6px;
  padding: 10px 15px;
  justify-content: center;
  align-items: center;
  border: 0.5px solid #ccc;
}

.upgrade-plan-full-container-div .card h1 {
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 25px;
}

.upgrade-plan-full-container-div .card span {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 20px;
}

.upgrade-plan-full-container-div .card span span {
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
}

.upgrade-plan-full-container-div .card p {
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  margin-top: 25px;
  margin-bottom: 30px;
}

.upgrade-plan-full-container-div .card ul li {
  list-style: none;
  display: flex;
  justify-content: center;
  justify-content: center;
  gap: 15px;
}

.upgrade-plan-full-container-div .card .upgrade-plan--bottom-button {
  display: flex;
  width: 210px;
  height: 35px;
  padding: 10px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  padding-bottom: 35px;
  width: 100%;
  left: 0;
}

.upgrade-plan-full-container-div .card .upgrade-plan--bottom-button button {
  color: #3968ed;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  width: 210px;
  height: 35px;
  padding: 10px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-color: #3968ed;
}

.fade {
  -webkit-mask-image: linear-gradient(180deg, #000 40%, transparent);
  mask-image: linear-gradient(180deg, #000 40%, transparent);
}

.upgrade-plan-full-container-div .card .background-fade {
  position: absolute;
  bottom: 53px;
  left: 0;
  width: 100%;
  height: 200px;
  background: -moz-linear-gradient(top,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(0%, rgba(255, 255, 255, 0)),
      color-stop(100%, rgba(255, 255, 255, 1)));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%);
  /* IE10+ */
  background: linear-gradient(to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0);
  /* IE6-9 */
}

.upgrade-plan-full-container-div .card.active {
  background-color: #3968ed;
  color: #fff;
}

.upgrade-plan-full-container-div .card.active .upgrade-plan--bottom-button {
  background-color: #3968ed;
}

.upgrade-plan-full-container-div .card .popular-card-top-left {
  width: 132.972px;
  height: 25.299px;
  position: absolute;
  top: 22px;
  left: -32px;
  color: #3968ed;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1px;
  background-color: #fff;

  text-transform: uppercase;

  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(-45deg);
}

/* Booking */

.priceBreakUpInnerTabs {
  padding: 5px 0px;
  text-transform: uppercase;
  cursor: pointer;
  margin-bottom: 10px;
}

.priceBreakUpInnerTabs.active {
  color: #3968ed;
  text-decoration: underline;
  text-decoration-thickness: 3px;
  text-underline-offset: 6px;
}

.create-booking-room-status {
  position: relative;
  cursor: pointer;
}

.create-booking-room-status:hover .create-booking-room-dates {
  opacity: 1;

  visibility: visible;
}

.create-booking-room-dates {
  position: absolute;
  bottom: 22px;
  right: 0;
  background-color: white;
  border: 0.5px solid #ccc;
  width: 220px;

  opacity: 0;

  z-index: 20;

  visibility: hidden;
}

.height-100 {
  height: 100%;
  overflow: hidden;
}

.booking-choice-full-parent-container-left-heading {
  color: #333;
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 25px;
  /* height: 40px; */
  display: flex;
  align-items: flex-end;
}

.booking-choice-full-parent-container-right {
  border-radius: 8px;
  width: 100%;
}

.booking-choice-full-parent-container-right-heading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  align-items: center;
}

.booking-choice-full-parent-container-right-heading-new {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  align-items: center;

  margin-bottom: 10px;
  position: sticky;
  top: 60px;
  background-color: white;
  z-index: 1;
}

/* .booking-choice-full-container {
  border-radius: 8px;
  background: #fff;
  width: 230px;
  overflow-y: scroll;
} */

.booking-choice-full-container::-webkit-scrollbar {
  width: 10px;
  border: 1px solid red;
}

.booking-choice-full-container::-webkit-scrollbar-track {
  background: #fff;
}

.booking-choice-full-container::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 8px;
}

.booking-table-full-container::-webkit-scrollbar {
  width: 10px;
  border: 1px solid red;
}

.booking-table-full-container::-webkit-scrollbar-track {
  background: #fff;
}

.booking-table-full-container::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 8px;
}

.booking-choice-full-container-divs {
  padding: 15px;
  border-bottom: 0.5px solid #ccc;
}

.booking-table-full-container {
  width: 100%;
  /* overflow-y: auto; */
  background-color: #fff;
  border-radius: 8px;
  /* padding-left: 15px;
  padding-right: 15px; */
  /* padding-bottom: 15px; */
  /* height: 600px; */
}

.booking-first-row-head {
  display: flex;
  padding: 10px 0;
  gap: 5px;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #ccc;
  color: #888;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.booking-first-row-head-divs {
  padding: 2px 4px;
  border-radius: 4px;
  cursor: pointer;
  color: #888;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* .booking-first-row-head-divs.active {
  padding: 10px;
  background: #ecf1fd;
} */
.booking-second-row-head {
  display: flex;
  padding: 10px;
  /* gap: 5px; */
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  color: rgba(102, 102, 102, 1);
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.booking-second-row-head-new {
  display: flex;
  padding: 10px;
  /* gap: 5px; */
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  color: rgba(102, 102, 102, 1);
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: sticky;
  cursor: default;
  top: 90px;
  background-color: white !important;
  z-index: 1;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

/* agents */
.booking-second-row-head-new-agent {
  display: flex;
  padding: 10px;
  /* gap: 5px; */
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  color: rgba(102, 102, 102, 1);
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background-color: white;

  /* 
  position: sticky;
  cursor: default;
  top: 90px;
  z-index: 1; */

  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.booking-second-row-head-divs {
  border-radius: 4px;
  cursor: pointer;
  width: 16%;
  font-size: 13px;
}

.booking-third-row-head {
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* border: 0.5px solid #ccc; */
  border-radius: 6px;
}

.booking-third-row-head-container p {
  color: #333;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  /* padding: 20px 0 15px 0px; */
}

.booking-third-row-one-full-div {
  /* padding: 10px; */
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;

  font-size: 12px;

  position: relative;
}

.booking-third-row-one-full-div-child {
  width: 16%;
}

.booking-third-row-bottom-buttons-full-div {
  display: flex;
  justify-content: space-between;
  align-items: "center";
  gap: 10px;
  padding: 10px;
}

/* THEME Buttons */

.BgThemeButton {
  font-family: "Inter";
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
  gap: 7px;
  font-style: normal;
  font-weight: 500;
  border-radius: 4px;
  padding: 10px 15px;
  background-color: #3968ed;
  border: none;
  cursor: pointer;
}

.BgOutlineWhite {
  font-family: "Inter";
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
  gap: 7px;
  font-style: normal;
  font-weight: 500;
  border-radius: 4px;
  border: 1px solid #fff;
  padding: 10px 15px;
  background-color: transparent;
  cursor: pointer;
}

.BgThemeButton:hover {
  background-color: #2f59cb;
}

.BgThemeButtonFullWidth {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  border-radius: 4px;
  padding: 10px 15px;
  background-color: #3968ed;
  font-family: Inter;
  border: none;
  cursor: pointer;
  width: 100%;
}

.BgThemeButtonFullWidth:hover {
  background-color: #2f59cb;
}

.WithoutBgButtonBlue {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  color: #3968ed;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  border-radius: 4px;
  background-color: transparent;
  /* padding: 10px 15px; */
  font-family: "Inter";
  border: none;
  cursor: pointer;
}

.WithoutBgButtonBlue:hover {
  text-decoration: underline;
}

.WithoutBgButtonForRates {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  color: #3968ed;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  border-radius: 4px;
  background: none;
  /* background-color: #fff; */

  font-family: "Inter";
  border: none;
  cursor: pointer;
}

.WithoutBgButtonForRates:hover {
  text-decoration: underline;
}

.BgOutlineThemeButton {
  color: #3968ed;
  background-color: transparent;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  gap: 7px;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  border-radius: 4px;
  padding: 10px;
  font-family: Inter;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #3968ed;
  /* z-index: 9; */

  position: relative;
}

.BgOutlineSendAgainButton {
  color: #3968ed;
  background-color: transparent;
  width: 100%;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  gap: 7px;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  border-radius: 4px;
  padding: 10px;
  font-family: Inter;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #3968ed;
  /* z-index: 9; */

  position: relative;
}

.BgOutlineThemeHoldButton {
  color: #bd941b;
  background-color: transparent;
  width: 100%;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  gap: 7px;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  border-radius: 4px;
  padding: 10px;
  font-family: Inter;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #bd941b;
  /* z-index: 9; */

  position: relative;
}

.BgOutlineThemeButton.active {
  background-color: #3968ed;
  color: white;
}

.BgOutlineBlackButton {
  color: #888888;
  background-color: transparent;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  gap: 7px;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  border-radius: 4px;
  padding: 10px;
  font-family: Inter;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #888888;
  z-index: 9;
}

.SettleBillButton {
  color: #e03838;
  background-color: transparent;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  gap: 7px;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  border-radius: 4px;
  padding: 10px;
  font-family: "Inter";
  border: none;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #e03838;
  z-index: 9;
}

.PlainCancelButton {
  color: #888888;
  background-color: transparent;
  display: flex;
  justify-content: center;
  gap: 7px;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  border-radius: 4px;
  padding: 10px;
  font-family: Inter;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  border-radius: 4px;
}

.PlainCancelButton:hover {
  text-decoration: none;
}

.BgOutlineThemeButton.active:hover {
  background-color: #2f59cb;
  color: white;
}

.CheckWithoutBgUnderlineBtn {
  color: #3968ed;
  text-align: right;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Inter;
  border: none;
  cursor: pointer;
  background: transparent;
}

.CheckWithoutBgUnderlineBtn:hover {
  text-decoration-line: none;
}

.BgOutlineThemeButton:hover {
  background-color: #f6f6f6;
}

/* dashboard 2 */
.dashboard-upper-buttons-container {
  width: 400px;
  display: flex;
  gap: 10px;
  margin-bottom: 25px;
  margin-left: -23px;
}

.dashboard2-full-container {
  display: flex;
  justify-content: center;
  /* max-width: 1200px; */
  gap: 15px;
}

.dashboard2-full-container-left {
  display: flex;
  max-width: 100%;
  flex-direction: column;
  align-items: center;
}

.dashboard2-full-container-right {
  display: flex;
  max-width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.dashboard2-full-container-right-action-center {
  /* width: 300px; */
  background: #fff;
  color: #333;
  padding: 18px;
  border-radius: 8px;
}

.dashboard2-full-container-right-action-center-heading {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.dashboard2-full-container-right-middle-white-wrapper {
  /* background: white;
  color: #666; */
  padding: 11px 11px 11px 11px;
  border-radius: 4px;
  /* height: fit-content;
  width: fit-content; */
}

.dashboard2-full-container-right-div-2 {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  /* width: 300px; */
  border-radius: 8px;
  border: 0.5px solid #ccc;
}

.dashboard2-full-container-right-div-3 {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 300px;
  border-radius: 8px;
  border: 0.5px solid #ccc;
}

.custom-slider-slider {
  border-radius: 20px;
  margin: 20px 0;
  width: 270px;
  height: 15px;
  background-image: linear-gradient(to right,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 1));
  position: relative;
}

.custom-slider-slider-inner {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 20px;
  height: 15px;
  background-image: linear-gradient(to right,
      rgba(255, 255, 255, 1),
      rgba(57, 104, 237, 1));
}

.custom-slider-slider-inner-white {
  position: absolute;
  top: 0;
  right: 0;
  height: 15px;
  background-color: #eee;
  border-radius: 20px;
}

.custom-slider-slider-circle-white {
  position: absolute;
  top: -2px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.15));
  z-index: 100;
}

.custom-slider-slider-circle-blue {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, 16%);
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: rgba(57, 104, 237, 1);
}

.custom-slider-slider-last-tick {
  position: absolute;
  top: -2px;
  right: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border-radius: 20px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.15));
}

.custom-slider-lines {
  position: absolute;
  top: 0;
  height: 15px;
  background-color: #fff;
  width: 3px;
}

/* Agent 2 */

.agent-full-container {
  background-color: #eff0f2;
  /* height: 100vh; */
}

.agent-full-container-div {
  background-color: #eff0f2;
  display: flex;
  justify-content: center;
  gap: 15px;
}

.agent-full-container-left-div1 {
  width: 580px;
  padding: 20px;
  display: flex;
  background-color: #fff;
  border-radius: 8px;
}

.agent-full-container-left-div1 div span {
  color: #888;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.agent-full-container-left-div1 div div {
  color: #333;
  margin-top: 16px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-weight: 600;
}

.agent-full-container-left-div2 {
  width: 580px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
}

.agent-full-container-left-div2 .booking-details-div {
  display: flex;
  justify-content: space-between;
  border-radius: 6px;
  border: 0.5px solid #ddd;
  padding: 20px 15px 15px 15px;
}

.agent-full-container-left-div2 .booking-details-div .booking-details-div-tag {
  color: #3968ed;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.agent-full-container-left-div2 .booking-details-div .booking-details-div-money {
  color: #333;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.agent-full-container-left-div2 .booking-details-div .booking-details-div-date {
  color: #888;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.agent-full-container-left-div2 .heading {
  color: #333;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
}

.agent-full-container-left {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.agent-full-container-right-div1 {
  padding: 20px;
  background-color: #fff;

  width: 300px;

  /* display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column; */
}

/* OTAs */
.add-ota-model-container {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  transition: all 1s;
  position: absolute;
  top: 30%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

  flex-direction: column;
  z-index: 10000000000000;
  width: 360px;
  padding: 25px;
  transition: all 5s ease-in;
  background-color: white;
  -webkit-box-shadow: 0px 0px 11px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 11px -6px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 11px -6px rgba(0, 0, 0, 0.75);
  border-radius: 8px;
}

.add-ota-model-container-heading {
  color: #333;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-ota-model-container p {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
}

/* Supplier */
.supplier-add-model-container {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  transition: all 1s;
  position: absolute;
  top: 20%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

  flex-direction: column;
  z-index: 10000000000000;
  width: 700px;
  padding: 25px;
  transition: all 5s ease-in;
  background-color: white;
  -webkit-box-shadow: 0px 0px 11px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 11px -6px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 11px -6px rgba(0, 0, 0, 0.75);
  border-radius: 8px;
}

/* Purchase section */
.purchase-details-section {
  width: max-content;
  height: 100px;
  border-radius: 8px;
  display: grid;
  grid-template-columns: auto auto auto;
  flex: 1;
  background-color: #fff;
  padding: 20px;
  margin-bottom: 25px;
  gap: 40px;
}

.purchase-details-section-div {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.purchase-details-section-div--number {
  color: #333;
  font-family: "Inter";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.purchase-details-section-div--text {
  color: #333;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.booking-first-row-head-divs.active {
  background-color: #3969ed2d;
  color: #3968ed;
}

/* Add stock Modal */

.stock-add-model-container {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  transition: all 1s;
  margin-left: auto;
  margin-right: auto;

  flex-direction: column;
  z-index: 10000000000000;
  /* width: 1200px; */
  padding: 25px;
  background-color: white;
  -webkit-box-shadow: 0px 0px 11px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 11px -6px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 11px -6px rgba(0, 0, 0, 0.75);
  border-radius: 8px;
}

.stock-add-model-container h4 {
  color: #333;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 15px;
}

.stock-add-model-container p {
  color: #333;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 25px;
}

/* settlement */

.settlement-add-model-container {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  transition: all 1s;
  margin-left: auto;
  margin-right: auto;

  flex-direction: column;
  z-index: 10000000000000;
  background-color: white;
  border-radius: 8px;
}

.settlement-add-model-container h4 {
  color: #333;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 15px;
}

.settlement-add-model-container p {
  color: #333;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 25px;
}

/* POLARIS */

/* Custom input fields  */
.Polaris-Labelled__LabelWrapper {
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: var(--p-space-1);
}

.Polaris-Label {
  -webkit-tap-highlight-color: transparent;
}

.Polaris-Label__Text {
  display: block;
  flex: 1 1 auto;
  color: currentColor;
  -webkit-tap-highlight-color: transparent;
}

.Polaris-Connected {
  --pc-connected-item: 10;
  --pc-connected-primary: 20;
  --pc-connected-focused: 30;
  position: relative;
  display: flex;
}

.Polaris-TextField {
  --pc-text-field-contents: 20;
  --pc-text-field-backdrop: 10;
  font-size: var(--p-font-size-200);
  font-weight: var(--p-font-weight-regular);
  line-height: var(--p-font-line-height-3);
  border: none;
  letter-spacing: initial;
  position: relative;
  display: flex;
  align-items: center;
  color: var(--p-color-text);
  cursor: text;
}

.Polaris-TextField .Polaris-TextField__Input.Polaris-TextField__Input_disabled {
  border-radius: 4px;
  background: #eee;
}

.Polaris-TextField__Backdrop {
  position: relative;
  position: absolute;
  z-index: var(--pc-text-field-backdrop);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--p-color-bg);
  border: var(--p-border-width-1) solid var(--p-color-border-input);
  border-top-color: #898f94;
  border-radius: var(--p-border-radius-1);
  pointer-events: none;
}

.Polaris-TextField__Backdrop__errors {
  position: relative;
  position: absolute;
  z-index: var(--pc-text-field-backdrop);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #ffeaea;
  border: 1.5px solid #e03838;
  border-radius: var(--p-border-radius-1);
  pointer-events: none;
}

.Password-TextField__Input {
  position: relative;
}

.Polaris-TextField input:focus-visible {}

.Polaris-TextField__Backdrop::after {
  content: "S";
  position: absolute;
  z-index: 1;
  top: -0.125rem;
  right: -0.125rem;
  bottom: -0.125rem;
  left: -0.125rem;
  display: block;
  pointer-events: none;
  box-shadow: 0 0 0 -0.125rem var(--p-color-border-interactive-focus);
  border-radius: var(--p-border-radius-1);
}

/* select */
.Polaris-Select {
  --pc-select-backdrop: 10;
  --pc-select-content: 20;
  --pc-select-input: 30;
  position: relative;
}

.Polaris-Select__Input {
  font-size: var(--p-font-size-200);
  font-weight: var(--p-font-weight-regular);
  line-height: var(--p-font-line-height-2);
  letter-spacing: initial;
  position: absolute;
  text-rendering: auto;
  top: 0;
  left: 0;
  z-index: var(--pc-select-input);
  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 0;
  -webkit-appearance: none;
  appearance: none;
  border: none;
}

.Polaris-Select__Content {
  font-size: var(--p-font-size-200);
  font-weight: var(--p-font-weight-regular);
  line-height: var(--p-font-line-height-3);
  border: none;
  letter-spacing: initial;
  position: relative;
  z-index: var(--pc-select-content);
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 2.25rem;
  padding: calc((2.25rem - var(--p-font-line-height-3) - var(--p-space-05)) / 2) var(--p-space-2) calc((2.25rem - var(--p-font-line-height-3) - var(--p-space-05)) / 2) var(--p-space-3);
}

.Polaris-Select__SelectedOption {
  flex: 1 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Polaris-Icon {
  display: block;
  height: 1.25rem;
  width: 1.25rem;
  max-height: 100%;
  max-width: 100%;
  margin: auto;
}

.Polaris-Text--visuallyHidden {
  position: absolute !important;
  top: 0;
  width: 0.0625rem !important;
  height: 0.0625rem !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  clippath: inset(50%) !important;
  border: 0 !important;
  white-space: nowrap !important;
}

.Polaris-Text--root {
  margin: 0;
  text-align: inherit;
}

.Polaris-Select__Icon svg {
  fill: red;
  width: 100px;
}

.Polaris-Icon__Svg,
.Polaris-Icon__Img {
  position: relative;
  display: block;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
}

/* custom */
.select select:focus-visible {
  outline: 2px solid #3e7dd5;
  outline-offset: 1.5px;
}

/* Loader */

.spinner {
  width: 4em;
  height: 4em;
  border: 0.5em solid rgba(0, 0, 0, 0.1);
  border-left-color: #7983ff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.spinner_parent {
  width: "100%";
  /* height: 80vh; */
  padding: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner_parent_small {
  width: "100%";
  /* height: 80vh; */
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* In Screen  Loader */

.inner_spinner_parent {
  width: "100%";
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-bottom: 120px; */
}

.inner_spinner {
  width: 4em;
  height: 4em;
  border: 0.5em solid rgba(0, 0, 0, 0.1);
  border-left-color: #000a95;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Bedding */
.bedding-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  align-items: center;
}

.bedding_text {
  color: #333;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.toggle_text {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-right: 5px;
}

.toggle-container {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 14px;
}

.toggle-checkbox-custom {
  display: none;
}

.toggle-slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #dddddd;
  border-radius: 15px;
  cursor: pointer;
  transition: 0.4s;
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 1px;
  bottom: 1px;
  background-color: white;
  border-radius: 50%;
  transition: 0.4s;
}

.toggle-checkbox-custom:checked+.toggle-slider:before {
  transform: translateX(15px);
  background-color: #15aa12;
}

/* Hotel Select Box */
.hotel_options_dropdown {
  /* margin-left: 16px;
  margin-bottom: 30px; */
  position: relative;
  width: 100%;
}

.hotel_options_dropdown_btn {
  /* width: max-content; */
  height: 37px;
  border-radius: 4px;
  border: 1px solid #aaa;
  /* background: #EFF0F2; */
  padding: 10px;
  border-radius: 4px;
  color: #333;
  line-height: 50px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
  align-items: center;
}

.hotel_options_dropdown_btn svg {
  margin-left: 5px;
}

.Polaris-IndexTable {
  z-index: 0;
}

.hotel_options_dropdown_options {
  position: absolute;
  top: 100%;
  left: 0;
  padding: 0;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 6px;
  background: #fff;
  z-index: 9;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
}

.footer_nav_bar_other_menus .other_menus_item {
  position: absolute;
  padding: 0;
  width: 165px;
  border: 1px solid #ccc;
  border-radius: 6px;
  background: #fff;
  z-index: 9;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
}

.other_menus_item_nav_items {
  position: absolute;
  bottom: -164%;
  left: -8px;
  padding: 10px;
  width: 144px;
  border: 1px solid #ccc;
  border-radius: 6px;
  background: #fff;
  z-index: 9;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
}

.other_menus_item {
  position: absolute;
  background: white;
  top: 38px;
  left: -52px;
  box-shadow: rgba(127, 155, 179, 0.1) 0px 4px 4px 0px,
    rgba(0, 0, 0, 0.1) 0px 6px 20px 0px;
  border-radius: 4px;
}

.hotel_options_dropdown_options_child,
.other_menus_item_list {
  text-transform: capitalize;
  padding: 10px;
  color: #333;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
  transition: 0.5s;
}

/* Search Component */
.navbar_search_content {
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #ddd;
  background: #eff0f2;
}

.booking_search_content {
  /* width: 100%; */
  display: flex;
  align-items: center;
  border-radius: 6px;
  border: 0.5px solid #aaa;
  background: #fff;
  height: 30px;
}

.booking_search_contentSmall {
  /* width: 100%; */
  display: flex;
  align-items: center;
  border-radius: 6px;
  border: 0.5px solid #aaa;
  background: #fff;
}

.booking_search_contentSmall input {
  appearance: none;
  -webkit-appearance: none;
  width: 100%;
  background: #eff0f2;
  /* height: 40px; */
  color: #666;
  font-size: 14px;
  border: none;
  padding: 0;
  margin: 0;
}

.navbar_search_content input,
.booking_search_content input {
  appearance: none;
  -webkit-appearance: none;
  /* width: 100%; */
  background: #eff0f2;
  /* height: 40px; */
  color: #666;
  font-size: 14px;
  border: none;
  padding: 0;
  margin: 0;
}

.booking_search_content input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.booking_search_content input,
.copylink_custom_input input {
  background-color: #fff;
}

.navbar_search_content svg,
.booking_search_content svg {
  margin: 0 10px;
}

.navbar_search_content input:focus-visible,
.booking_search_content input:focus-visible,
.copylink_custom_input input:focus-visible {
  outline: none;
}

.copylink_custom_input_container {
  display: flex;
}

.copylink_custom_input_container .copylink_custom_input {
  width: 80%;
  display: flex;
  align-items: center;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background: #fff;
  border-top: 0.5px solid #888;
  border-left: 0.5px solid #888;
  border-bottom: 0.5px solid #888;
  border-right: none;
}

.copylink_custom_input_container .copylink_custom_input .copylink_custom_input_icon {
  border-right: 0.5px solid #888;
  height: 100%;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.copylink_custom_input_container .copylink_custom_input input {
  width: 100%;
  border: none;
  padding-left: 10px;
}

.amenitiesInput {
  min-height: 2.25rem;
  font-size: 0.875rem;
  font-weight: 400;
  font-size: var(--p-font-size-200);
  font-weight: var(--p-font-weight-regular);
  line-height: var(--p-font-line-height-2);
  letter-spacing: initial;
  position: relative;
  z-index: var(--pc-text-field-contents);
  display: block;
  flex: 1 1;
  width: 100%;
  min-width: 0;
  min-height: 2.25rem;
  margin: 0;
  padding: calc((2.25rem - var(--p-font-line-height-3) - var(--p-space-05)) / 2) var(--p-space-3);
  background: none;
  border: var(--p-border-width-1) solid transparent;
  font-family: var(--p-font-family-sans);
  -webkit-appearance: none;
  appearance: none;
  caret-color: var(--p-color-text);
  color: var(--p-color-text);
  border: 1px solid rgba(171, 177, 186, 1);
  border-radius: 0.25rem;
}

.form-container-div-full input:focus-visible {
  outline: 2px solid #3e7dd5;
  outline-offset: 1.5px;
}

/* inventory */

/* New UI for inventory */

.inventory_buttons_hold_block_hover:hover .inventory_buttons_hold_block {
  display: block;
  opacity: 1;
}

.inventory_unblock_icon {
  opacity: 0;
  display: none;
  transition: opacity 1s;
}

.inventory_icon_parents:hover .inventory_unblock_icon {
  opacity: 1;
  display: block;
  transition: opacity 1s;
  transition: 1s all ease-in;
}

.inventory_icon_parents:hover .inventory_block_icon {
  opacity: 0;
  display: none;
  transition: opacity 1s;
}

.inventory_buttons_hold_block {
  opacity: 0;
  display: none;
  transition: opacity 1s ease-in-out;
}

.inventory-options-tabs-menu {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  transition: all 1s;
  margin-left: auto;
  margin-right: auto;

  flex-direction: column;
  z-index: 10000000000000;
  width: 1200px;
  padding: 0px 25px 25px 25px;
  background-color: white;
}

.inventory-options-tabs-menu h4 {
  color: #333;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 15px;
}

.inventory-options-tabs-menu p {
  color: #333;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 25px;
}

.inventory-dates .inventory-dates-div:first-child {
  background-color: #3968ed;
  color: #fff;
}

.inventory-dates-div {
  padding: 10px 26px;
  width: 80px;
  color: #888;
  border-radius: 4px;
  background: #dddfe2;
  display: flex;
  justify-content: "center";
  align-items: "center";
  flex-direction: "column";
}

.inventory-full-container {
  border-radius: 8px;
  background: #fff;
  padding: 10px 20px;
}

.inventory-first-row-head {
  display: flex;
  gap: 5px;
  justify-content: flex-start;
  align-items: center;
  /* border-bottom: 1px solid #ccc; */
  color: #888;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.inventory-second-column-2div {
  margin: 20px 0;
}

.inventory-upper-date {
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.inventory-upper-month {
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.inventory-INRPrice {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.inventory-third-column-3div {
  margin-top: 22px;
  margin-bottom: 15px;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

.inventory-1st-column-inner-input {
  display: inline-flex;
  height: 25px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 0.5px solid #333;
}

.inventory-1st-column-inner-input-bottom-text {
  color: #888;
  text-align: center;
  font-family: Inter;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.inventory-fourth-column-4div {
  margin-bottom: 20px;
}

.updates-rates-button {
  color: #3968ed;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

.inventory-roomName {
  color: #333;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

/* 
.inventoryRoomOpen {
  height: max-content;
  overflow: visible;
  transition: 0.5s all ease-in;
}

.inventoryRoomClose {
  height: 62px;
  overflow: hidden;
  transition: 0.5s all ease-in;
} */

/* Checkbox Create Booking */
.roomcategories_content {
  display: flex;
}

.room_types {
  color: #333;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 5px;
  height: 18px;
}

.rooms_checkbox {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  border-radius: 4px;
  background-color: aqua;
}

.booking_details_child {
  border-radius: 8px;
  margin-bottom: 10px;
}

.booking_details_child_main {
  /* cursor: pointer; */
  padding: 0 15px;
  border: 0.5px solid #ccc;
  margin-bottom: 20px;
  transition: 1s all ease-in-out;
}

.booking_details_child_heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  cursor: pointer;
}

.booking_list_view {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
}

.booking_details_child h4 {
  font-size: 14px;
  /* color: #666; */
}

.booking_details_child_heading h5 {
  font-size: 14px;
  color: #333;
}

.booking_list_view h5 span {
  color: #15aa12;
}

.checkin_details_heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  width: 70%;
}

.checkin_details_heading h4 {
  color: #666;
  font-size: 14px;
}

.checkin_details_column_list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
}

.checkin_list_view {
  border-bottom: 0.5px solid #ddd;
  padding: 10px 0;
}

.checkin_details_column {
  display: flex;
  justify-content: space-between;
}

.checkin_details_column_list h5 {
  color: #333;
  font-size: 14px;
}

/* FILE UPLOADER */
.file_content_block {
  position: relative;
  width: 100%;
  min-height: 100px;
  max-height: 180px;
  border: 1px dashed rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  overflow-y: scroll;
  background-color: #fff;
}

.file_content_block:hover {
  background-color: #f7f3f3;
}

.dropzone_file_input {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  opacity: 0;
}

.dropzone_display_img_name {
  margin-left: "10px";
  color: "#888";
  font-size: "10px";
  font-family: "Inter";
}

.file_content_block::-webkit-scrollbar {
  width: 5px;
  color: white;
}

.file_content_block::-webkit-scrollbar-track {
  border-radius: 5px;
}

.file_content_block::-webkit-scrollbar-thumb {
  background: rgb(190, 190, 190);
  border-radius: 10px;
  height: 30px;
}

.file_content_block::-webkit-scrollbar-thumb:hover {
  background: rgb(168, 166, 166);
}

.dropzone_filesList {
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-left: 10px;
}

.dropzone_display_img_name {
  margin-left: 10px;
  color: #888;
  font-size: 13px;
  font-family: "Inter";
}

.dropzone_add_files_button {
  width: fit-content;
  padding: 5px;
  border-radius: 4px;
  background: #ecf1fd;
  color: #3968ed;
  font-size: 14px;
  font-family: "Inter";
  margin: 0 auto;
}

.dropzone_drop_message {
  width: fit-content;
  color: #888;
  font-size: 12px;
  font-family: "Inter";
  margin: 10px auto;
}

.dropzone_btnContainer {
  position: relative;
}

.dropzone_removeFilebtn {
  border: none;
  outline: none;
  cursor: pointer;
  margin-left: 5px;
  background: transparent;
  position: absolute;
  top: -26px;
}

.property-details-section {
  width: 100%;
  border-radius: 8px;
  background-color: #fff;
  padding: 15px 30px 15px 15px;
  /* margin-bottom: 25px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.add_first_property {
  text-align: center;
}

/* Invoice */
.Invoice_page {
  border-bottom: 5px solid #3968ed;
  /* width: 800px; */
  margin: 0 auto;
  background: white;
  padding: 20px;
}

.invoice_container {
  width: 800px;
  margin: 12px auto;
}

.invoice_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}

.Invoice_heading {
  color: #3968ed;
  text-align: right;
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.invoice_container .property_headings {
  color: #060606;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin: 10px 0;
}

.invoice_container .property_large {
  width: 150px;
  text-align: left;
}

.invoice_container .property_small {
  width: 10px;
}

.invoice_container .property_medium {
  width: 150px;
}

.invoice_billDetails {
  display: flex;
  justify-content: space-between;
}

.invoice_container .property_address {
  color: #474646;
  font-family: "Inter";
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  padding-bottom: 7px;
}

.Invoice_bills {
  height: 15px;
}

.invoice_container .property_paragraph {
  color: #474646;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
}

.addressInvoice {
  padding: 0 0 40px 0;
}

.invoice_details {
  width: 49%;
  flex-shrink: 0;
  border-radius: 6px;
  border: 0.5px solid #3968ed;
  background: #ecf1fd;
  padding: 1.5rem 1rem;
}

.transaction-table-container {
  border: 1px solid #3968ed;
  border-radius: 6px;
  overflow: hidden;
}

.transaction-table {
  width: 100%;
  margin: 0;
  border-collapse: collapse;
}

.table-header {
  color: #060606;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  background-color: #3968ed;
  color: white;
  padding: 10px;
  text-align: left;
}

.table-header-events {
  color: #060606;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: black;
  padding: 10px;
  text-align: left;
}

.table-head-row {
  border-bottom: 1px solid #3968ed;
}

.table-data {
  color: #333;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  padding: 10px;
}

.amount_to_pay {
  width: 200px;
  margin: 0 70px 0 auto;
}

.invoice_charges {
  display: flex;
  height: 20px;
}

.invoice_total {
  width: 50%;
  color: #333;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.invoice_money {
  width: 50%;
  text-align: right;
  color: #000000;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.poweredBy {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 30px 0 30px auto;
  width: 100px;
  height: 40px;
}

.poweredBy_text {
  color: #888;
  width: fit-content;
  font-family: "Inter";
  font-size: 12px;
  height: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.share_preview_bill {
  border-radius: 8px;
  background: #fff;
  padding: 20px;
}

.share_preview_bill h2 {
  font-size: 20px;
  padding-bottom: 10px;
}

.share_preview_bill .share_preview_bill_child {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0;
  cursor: pointer;
  padding: 5px 20px;
}

.share_preview_bill .share_preview_bill_child.active {
  background-color: rgba(221, 224, 228, 1);
  border-radius: 10px;
  transition: 0.5 all ease;
}

.share_preview_bill .share_preview_bill_child h3 {
  font-size: 16px;
  color: #333;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.share_whatsappbill_input {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.share_whatsappbill_input input {
  border-radius: 4px;
  border: 0.5px solid #333;
  height: 37px;
  padding: 10px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top: 0.5px solid #888;
  border-left: 0.5px solid #888;
  border-bottom: 0.5px solid #888;
  border-right: 0.5px solid #888;
}

.share_whatsappbill_input input:focus,
.share_whatsappbill_input input::-webkit-outer-spin-button,
.share_whatsappbill_input input::-webkit-inner-spin-button {
  outline: none;
  appearance: textfield;
  -moz-appearance: textfield;
  -webkit-appearance: none;
}

/* Settings */
.settings_banner {
  margin-top: 70px;
  max-width: calc(50vw + 50%);
  height: 250px;
  width: 100%;
  background: linear-gradient(45deg, #7c9cf3 0%, #3e6ced 59.38%, #3968ed 100%);
}

.chnage_cover_btn {
  max-width: 1300px;
  margin: 0 auto;
  padding-top: 30px;
  display: flex;
  justify-content: flex-end;
}

.settings_sec {
  margin-top: -20px;
  padding-bottom: 80px;
}

.settings_main {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.settings_sec_left {
  width: 30%;
  border-radius: 10px;
  background: #fff;
  padding: 20px;
  text-align: center;
  height: 100%;
}

/* 
  .settings_sec_right {
    width: 200px;
  } */

.settings_sec_left .settings_sec_left_image {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-color: #dae3fc;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.settings_sec_left_image .image_overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 0.5s;
}

.settings_sec_left_image:hover .image_overlay {
  opacity: 1;
}

.settings_sec_left h3 {
  font-size: 18px;
  color: #333;
  font-weight: 700;
  padding-top: 20px;
  padding-bottom: 10px;
}

.settings_sec_left .settings_sec_left_image p {
  font-size: 30px;
  color: #3968ed;
}

.settings_sec_left_address {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.settings_sec_left_edit_btn {
  color: #3968ed;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  margin-bottom: 20px;
  background-color: transparent;
  cursor: pointer;
}

.settings_sec_left_content h5 {
  color: #666;
  font-size: 15px;
  font-weight: 500;
  margin: 5px 0;
}

.settings_sec_left_content h4 {
  color: #333;
  font-size: 15px;
  font-weight: 600;
}

.settings_sec_right {
  width: 70%;
}

.profile_tab_form h3 {
  color: #333;
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 10px;
}

.custom_tab_container .custom_tab_header {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  background-color: #fff;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding: 0 20px;
  margin-bottom: 20px;
}

.custom_tab_container .custom_tab_header .tab_item {
  color: #888;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 20px 0;
  transition: 0.5s;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  margin-right: 30px;
}

.custom_tab_container .custom_tab_header .tab_item.active {
  color: #3968ed;
  border-bottom: 2px solid #3968ed;
}

.profile_tab_form,
.status_sec,
.setting_location_sec,
.setting_domains_sec {
  background-color: #fff;
  padding: 20px;
  width: 100%;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  margin-bottom: 20px;

  display: flex;
  gap: 10px;
  flex-direction: column;
}

.profile_tab_form {
  border-radius: 10px;
}

.profile_bottom_left .status_sec {
  border-radius: 8px;
  background-color: #fff;
  padding: 20px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.profile_bottom_right .status_sec h3 {
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 10px;
}

.profile_bottom {
  display: flex;
  gap: 10px;
}

.profile_bottom .profile_bottom_left,
.profile_bottom .profile_bottom_right {
  width: 50%;
}

/* .profile_bottom_left {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
} */

.setting_location {
  display: flex;
  justify-content: space-between;

  gap: 10px;
  border-radius: 8px;
  border: 0.5px solid #ccc;
  padding: 20px;
}

.setting_location .setting_location_div {
  display: flex;
  gap: 10px;
  border-radius: 8px;
}

.setting_location .setting_location_image {
  width: 45px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: 0.5px solid #ccc;
}

/* new dashboard */
.dashboard_page {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.dashboard_page .dashboard_page_left {
  width: 70%;
}

.dashboard_page .dashboard_page_right {
  width: 30%;
  position: sticky;
  top: 48px;
  height: 100%;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  flex-direction: column;
  gap: 20px;
  /* border-radius: 10px; */
  /* background: #FFF; */
  /* height: 200px; */
  margin: 20px 0;
}

.dashboard_page_left .checkin_checkout_sec,
.dashboard_page_left .checkin_rooms_status,
.dashboard_page_right .day_status_main,
.dashboard_page_right .sponsored_listing,
.dashboard_page_right .suggestions_sec,
.dashboard_page_right .dashboard_chart {
  border-radius: 10px;
  background: #fff;
  padding: 20px;
  margin: 20px 0;
}

.checkin_rooms_status {
  border-radius: 10px;
  background: #fff;
  padding: 20px;
  margin: 20px 0;
}

.dashboard_page_right .suggestions_sec {
  background: #3968ed;
}

.arrival_box {
  border-radius: 8px;
  background-color: #5780ef;
  padding: 20px;
  width: 33%;
}

.arrival_box.active {
  border-radius: 8px;
  background: #3968ed;
  padding: 20px;
  width: 33%;
}

.arrival_box .arrival_box_icon {
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.2);
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrival_box .arrival_box_content {
  min-width: 100px;
}

.arrival_box .arrival_box_content p {
  color: rgba(255, 255, 255, 0.7);
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.arrival_box .arrival_box_content h3 {
  color: #fff;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}

.arrival_box_button button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.progress_bar_main {
  margin-top: 30px;
  margin-bottom: 20px;
}

.custom-progress-bar-container {
  width: 100%;
  height: 6px;
  background-color: #ffffff7d;
  border-radius: 20px;
  overflow: hidden;
}

.custom-progress-bar {
  height: 100%;
  background-color: #ffffff;
  transition: width 0.3s ease-in-out;
}

.arrival_box .checkedin_test {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.checkin_rooms_status .room_status_heading {
  color: #333;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.checkin_rooms_status .rooms_status_box {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
}

.rooms_status_box_left h4,
.dashboard_chart_top_bar_left h4 {
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.rooms_status_box_left h2,
.dashboard_chart_top_bar_left h2 {
  color: #333;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
}

.rooms_status_box_right .rooms_status {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  border: 0.5px solid #ccc;
}

.rooms_status {
  display: flex;
  padding: 10px;
}

.rooms_status .rooms_status_child {
  flex: 1;
  border-radius: 5px;
  color: white;
}

.rooms_status_child h5 {
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  /* width: fit-content; */
  line-height: normal;
  text-transform: uppercase;
  padding: 10px;
  text-align: center;
}

.rooms_status_child h3 {
  color: #333;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  padding-top: 15px;
}

.rooms_status_child.blue_child h5 {
  border-radius: 4px;
  background: #ecf1fd;
  color: #3968ed;
}

.rooms_status_child.red_child h5 {
  border-radius: 4px;
  background: #ffeaea;
  color: #e03838;
}

.rooms_status_child.green_child h5 {
  border-radius: 4px;
  background: #dff8df;
  color: #15aa12;
}

.rooms_status_child.yellow_child h5 {
  border-radius: 4px;
  background: #fff4d2;
  color: #e0ae18;
  /* width: max-content; */
}

.rooms_status_child.grey_child h5 {
  border-radius: 4px;
  background-color: rgb(238, 238, 238);
  color: rgb(136, 136, 136);
  /* width: max-content; */
}

.day_status_main .day_status_sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.day_status_sec h2 {
  color: #3968ed;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.sponsored_listing h4 {
  color: #333;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 20px 0;
}

.sponsored_listing h5 {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 20px 0;
}

.sponsored_listing p {
  color: #666;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 20px;
}

.suggestions_sec h3 {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.suggestions_sec .suggestions_sec_para {
  color: #fff;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 20px 0;
}

.suggestions_sec .suggestions_sec_box {
  background: white;
  color: #666;
  border-radius: 4px;
  padding: 10px;
}

.suggestions_update_button {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.dashboard_key_value_main {
  width: 100%;
  height: 50px;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard_key_value_main h4 {
  color: #333;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  line-height: 18px;
  font-weight: 500;
  line-height: normal;
}

.dashboard_key_value_main h2 {
  color: #333;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.dashboard_chart .chart_data_table h4 {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin: 8px 0;
}

.dashboard_chart .dashboard_chart_graph {
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.dashboard_chart .chart_data_table h4 div {
  width: 18px;
  height: 18px;
  background-color: #15aa12;
  border-radius: 5px;
}

.dashboard_chart .chart_data_table h2 {
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

/* bulk Update Date Picker */
.dateRangePickerContainer {
  cursor: pointer;
  /* width: 40px;
  height: 30px; */
  padding: 10px;
  border-radius: 4px;
  border: 0.5px solid #888;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dateRangePickerButtonone {
  border: none;
  outline: none;
  background-color: #fff;
  background: transparent;
  width: 30px;
  height: 100%;
  cursor: pointer;
}

.dateRangePickerButtontwo {
  border: none;
  outline: none;
  background-color: #fff;
  background: transparent;
  width: 30px;
  height: 100%;
  cursor: pointer;
}

.react-datepicker-wrapper {
  cursor: pointer;
}

/* preview bill */

.booking-choice-full-parent-container-left {
  border-radius: 8px;
  position: sticky;
  top: 70px;
  height: 100%;
}

/* .booking-choice-full-parent-container-left-heading {
  color: #333;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 25px;
 
} */

/* .booking-choice-full-parent-container-right {
  width: 300px;
  height: 150px;
  border-radius: 8px;
  background: #fff;
} */

.booking-choice-full-parent-container-right-heading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  align-items: center;
}

.booking-choice-full-container {
  border-radius: 8px;
  background: #fff;
  width: 230px;
  /* overflow-y: scroll; */
}

.booking-choice-full-container::-webkit-scrollbar {
  width: 10px;
  border: 1px solid red;
}

.booking-choice-full-container::-webkit-scrollbar-track {
  background: #fff;
}

.booking-choice-full-container::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 8px;
}

.booking-table-full-container::-webkit-scrollbar {
  width: 10px;
  border: 1px solid red;
}

.booking-table-full-container::-webkit-scrollbar-track {
  background: #fff;
}

.booking-table-full-container::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 8px;
}

.booking-choice-full-container-divs {
  padding: 15px;
  border-bottom: 0.5px solid #ccc;
}

/* .booking-table-full-container {
  width: 100%;
  overflow-y: scroll;
  background-color: #fff;
  border-radius: 8px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;

} */

.booking-first-row-head {
  display: flex;
  padding: 10px 0;
  gap: 5px;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #ccc;
  color: #888;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

/* .booking-first-row-head-divs {
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  color: #888;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
} */

/* .booking-first-row-head-divs.active {
  padding: 10px;
  background: #ecf1fd;
} */

/* .booking-second-row-head {
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  color: #888;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
} */

.booking-third-row-head {
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* gap: 10px; */
}

/* .booking-third-row-head-container  {
  max-height: 700px;
  overflow: auto;
} */

.booking-third-row-one-full-div-child {
  width: 16%;
}

.booking-third-row-bottom-buttons-full-div {
  display: flex;
  justify-content: space-between;
  align-items: "center";
  gap: 10px;
  margin-top: 8px;
}

/* .main_container {
  padding: 50px 50px;
  width: 100vw;
  background: #eff0f2;
  overflow: hidden;

} */

.poweredBy {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 30px 0 30px auto;
  width: 100px;
  height: 40px;
}

.poweredBy_text {
  color: #888;
  width: fit-content;
  font-family: "Inter";
  font-size: 12px;
  height: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.share_preview_bill {
  border-radius: 8px;
  background: #fff;
  padding: 20px;
}

.share_preview_bill h2 {
  font-size: 20px;
  padding-bottom: 10px;
}

.share_preview_bill .share_preview_bill_child {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0;
  cursor: pointer;
}

.share_preview_bill .share_preview_bill_child h3 {
  font-size: 16px;
  color: #333;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.share_whatsappbill_input {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.share_whatsappbill_input input {
  border-radius: 4px;
  border: 0.5px solid #333;
  height: 37px;
  padding: 10px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top: 0.5px solid #888;
  border-left: 0.5px solid #888;
  border-bottom: 0.5px solid #888;
  border-right: none;
}

.share_whatsappbill_input input:focus,
.share_whatsappbill_input input::-webkit-outer-spin-button,
.share_whatsappbill_input input::-webkit-inner-spin-button {
  outline: none;
  appearance: textfield;
  -moz-appearance: textfield;
  -webkit-appearance: none;
}

.reduceWidthInPrint {
  width: 380px;
}

.editButtonPrintHideDynamic {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 10px;
}

.goBackBtn {
  margin-bottom: 20px;
}

@media print {
  .goBackBtn {
    display: none;
  }

  .booking-choice-full-parent-container-right {
    display: none;
  }

  .booking-choice-full-parent-container-left {
    print-color-adjust: exact;
  }

  .reduceWidthInPrint {
    width: 330px;
  }

  .editButtonPrintHideDynamic {
    display: none;
  }

  .main_container {
    padding-top: 0;
  }
}

/* preview bill input value */
.inputDynamicPreviewBill {
  padding: 5px 1px;
  background-color: var(--p-color-bg);
  border: var(--p-border-width-1) solid var(--p-color-border-input);
  border-top-color: #898f94;
  border-radius: var(--p-border-radius-1);
  font-weight: var(--p-font-weight-regular);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
}

.Invoice_page {
  border-bottom: 5px solid #3968ed;
  /* width: 800px; */
  margin: 0 auto;
  background: white;
  padding: 20px;
}

.invoice_container {
  width: 800px;
  margin: 12px auto;
}

.invoice_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}

.Invoice_heading {
  color: #3968ed;
  text-align: right;
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.invoice_container .property_headings {
  color: #060606;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin: 10px 0;
}

.invoice_container .property_large {
  width: 150px;
  text-align: left;
}

.invoice_container .property_small {
  width: 10px;
}

.invoice_container .property_medium {
  width: 150px;
}

.invoice_billDetails {
  display: flex;
  justify-content: space-between;
}

.invoice_container .property_address {
  color: #474646;
  font-family: "Inter";
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  padding-bottom: 7px;
}

.Invoice_bills {
  height: 15px;
}

.invoice_container .property_paragraph {
  color: #474646;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
}

.addressInvoice {
  padding: 0 0 0 0;
}

.invoice_details {
  width: 45%;
  flex-shrink: 0;
  border-radius: 6px;
  border: 0.5px solid #3968ed;
  background: #ecf1fd;
  padding: 1.5rem 1rem;
}

.transaction-table-container {
  border: 1px solid #3968ed;
  border-radius: 6px;
  overflow: hidden;
}

.transaction-table {
  width: 100%;
  margin: 0;
  border-collapse: collapse;
}

.table-header {
  color: #060606;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  background-color: #3968ed;
  color: white;
  padding: 10px;
  text-align: left;
}

.table-data {
  color: #333;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  padding: 10px;
}

.amount_to_pay {
  width: 200px;
  margin: 0 70px 0 auto;
}

.invoice_charges {
  display: flex;
  height: 20px;
}

.invoice_total {
  width: 50%;
  color: #333;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.invoice_money {
  width: 50%;
  text-align: right;
  color: #000000;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.poweredBy {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 30px 0 30px auto;
  width: 100px;
  height: 40px;
}

.poweredBy_text {
  color: #888;
  width: fit-content;
  font-family: "Inter";
  font-size: 12px;
  height: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.share_preview_bill {
  border-radius: 8px;
  background: #fff;
  padding: 20px;
}

.share_preview_bill h2 {
  font-size: 20px;
  padding-bottom: 10px;
}

.share_preview_bill .share_preview_bill_child {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0;
  cursor: pointer;
}

.share_preview_bill .share_preview_bill_child h3 {
  font-size: 16px;
  color: #333;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.share_whatsappbill_input {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.share_whatsappbill_input input {
  border-radius: 4px;
  border: 0.5px solid #333;
  height: 37px;
  padding: 10px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top: 0.5px solid #888;
  border-left: 0.5px solid #888;
  border-bottom: 0.5px solid #888;
  border-right: none;
}

.share_whatsappbill_input input:focus,
.share_whatsappbill_input input::-webkit-outer-spin-button,
.share_whatsappbill_input input::-webkit-inner-spin-button {
  outline: none;
  appearance: textfield;
  -moz-appearance: textfield;
  -webkit-appearance: none;
}

@media print {
  .nav-header {
    display: none;
  }

  .navbar-container {
    display: none;
  }

  .footer_nav_bar {
    display: none;
  }

  .booking-choice-full-parent-container-right {
    display: none;
  }

  .booking-choice-full-parent-container-left {
    print-color-adjust: exact;
  }
}

.setting_domains_sec .setting_domain_input h4 {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 20px;
}

.setting-dummy-input-heading {
  font-size: 16px;
  font-weight: 500;
}

.setting-dummy-input-child {
  font-size: 14px;
  margin-top: 8px;
  margin-left: 10px;
}

/* Add Competitors */

.customeraddbox_select {
  width: 100%;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 2px;
  padding: 5px;
  border-radius: 4px;
}

.customeraddbox_select :focus {
  border: none;
  outline: none;
}

.customeraddbox_unorderedlist {
  width: 100%;
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  padding: 0;
}

.customeraddboxlist {
  list-style: none;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 2px;
  background: #eee;
  padding: 5px;
  width: fit-content;
}

.customeraddboxButton {
  border: none;
  background: transparent;
  cursor: pointer;
  margin: 0 0 0 5px;
  padding: 0;
}

/* Reservation Part Booking */
.reservation_bar_container {
  /* width: 905px; */
  height: 30px;
  flex-shrink: 0;
  border-radius: 6px;
  background: #fff;
  display: flex;
  align-items: center;
  gap: 10px;
}

.reservation_dateRange {
  color: #333;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  width: fit-content;
  height: 50px;
  flex-shrink: 0;
  padding: 10px;
  /* border-right: 0.5px solid #ddd; */
}

.reservation_dateRange.toggleAccord {
  position: relative;
  /* cursor: pointer; */
}

.accord_container {
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 45px;
  top: 55px;
  color: #333;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 5px;
  gap: 5px;
  width: fit-content;
  background-color: #fff;
  cursor: pointer;
  border-radius: 4px;
  width: max-content !important;
}

.accord_container_values {
  padding: 5px;
}

.accord_container_values:hover {
  background-color: #f1f1f1;
}

.reservation_dateRange .reservation_dateRange_calender_div {
  height: 50px;
  padding-top: 10px;
}

.reservation_total {
  height: 30px;
  width: fit-content;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  border-right: 0.5px solid #ddd;
  padding: 10px;
}

.reservation_total_heading {
  color: #333;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  padding: 0;
}

/* .reservation_total_paragraph.thisMonth {
  height: 50px;
  padding-top: 19px;
} */

.accordImageRotate {
  transform: rotate(180deg);
}

.reservation_total_paragraph {
  color: #333;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  padding: 0;
}

.reservation_total.pending {
  border: none;
}

/* .reservation_total.confirmed {
  width: 110px;
} */

.reservation_downloadImage {
  margin: auto 10px auto auto;
}

/* download popup */
.reservationData_downloadPopup {
  width: 100%;
  height: 151px;
  border-radius: 8px;
  background: #fff;
  margin-top: 0;
  padding: 0;
  padding-top: 20px;
}

.reservationdata_doenloadPopup_heading {
  color: #333;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  width: 270px;
  margin: 0 auto;
}

.reservation_downloadImage_button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.reservationdata_doenloadPopup_button {
  display: flex;
  height: 35px;
  padding: 10px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid #3968ed;
  background: #3968ed;
  color: #fff;
  cursor: pointer;
}

.reservationdata_doenloadPopup_selectdate_container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0 auto;
  width: 353px;
  height: 35px;
  margin-top: 25px;
}

.webpage_box3 {
  width: 100%;
  height: 100px;
  border-radius: 0px 0px 10px 10px;
  background: #fff;
  display: flex;
  align-items: center;
}

.webpage_box3_image {
  width: 65px;
  height: 65px;
  border-radius: 4px;
  background: url("/public/website-image.png"), lightgray 50% / cover no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
  margin-left: 10px;
  margin-right: 10px;
}

.webpage_box3_textcontent {
  height: 65px;
  padding: 0;
}

.webpage_box3_textcontent_heading {
  color: #333;
  text-align: center;
  /* setting add staff */
}

.addStaff_container {
  width: 700px;
  height: 662px;
  border-radius: 8px;
  background: #fff;
  border: 1px solid black;
  padding: 25px;
}

.addstaff_heading,
.permission_heading {
  color: #333;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  padding: 0;
}

.webpage_box3_textcontent_paragraphs {
  margin: 0;
  padding: 5px;
  color: #15aa12;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 10px;
  background: #dff8df;
}

.webpage_box3_textcontent_paragraphs_white {
  color: #888;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  padding-top: 5px;
}

.webpage_box4 {
  width: 100%;
  border-radius: 10px;
  margin-top: 20px;
  background: #fff;
  padding: 20px;
}

.permission_heading {
  margin: 20px;
}

.permission_paragraph {
  margin: 0 20px 20px 20px;
}

.permission_checkBox_container>label {
  color: #333;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}

.webpage_box4_paragraph {
  color: #888;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
}

.addstaff_user_email>label,
.addstaff_user_name>label,
.permission_paragraph {
  color: #333;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.addstaff_user_name_input[type="text"],
.addstaff_user_email_input[type="email"] {
  width: 318px;
  height: 30px;
  border-radius: 4px;
  border: 0.5px solid #333;
  margin-top: 10px;
  padding: 4px;
}

.permission_container {
  border-radius: 8px;
  border: 0.5px solid #ccc;
  margin-top: 10px;
  border-bottom: none;
}

.permission_checkBox_container {
  border-bottom: 0.5px solid #ccc;
  padding-bottom: 10px;
}

.permission_item-accords {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 312px;
  border-radius: 8px;
  border-bottom: 0.5px solid #ccc;
}

.permission_item-accords::-webkit-scrollbar {
  width: 6px;
}

.permission_item-accords::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.permission_item-accords::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.4);
}

.permission_item-accords::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.addstaff_accordion-title {
  color: #333;
  /* font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 12px 0 25px 0; */
}

.webpage_box4_child {
  margin: 0;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.webpage_box4_child_card {
  width: 30%;
}

.webpage_box4_child_card_image {
  width: 100%;
  height: 195px;
  border-radius: 8px 8px 0px 0px;
  background: url("/public/website-image.png"), lightgray 50% / cover no-repeat;
  margin: 0;
}

.webpage_box4_child_card_text {
  height: 60px;
  border-radius: 0px 0px 8px 8px;
  border: 0.5px solid #ccc;
  background: #fff;
  margin: 0;
  padding: 0;
  display: flex;
  padding-left: 13px;
  padding-bottom: 17px;
}

.webpage_box4_child_card_text_container {
  margin: 0;
  padding: 0;
}

.webpage_box4_child_card_text_heading {
  color: #3968ed;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
  margin: 10px 0 0 0;
  padding: 0;
}

.webpage_box4_child_card_text_paragraph {
  color: #888;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 10px 0 0 0;
}

.webpage_box4_child_card_button_container {
  width: 72px;
  margin: 10px 10px auto auto;
}

.webpage_box4_child_card_button {
  display: inline-flex;
  cursor: pointer;
  height: 30px;
  padding: 10px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 0.5px solid #888;
  background: #fff;
  color: #888;
  font-family: Inter;
}

.accordImageRotate {
  transform: rotate(180deg);
}

.ck-editor__main {
  border: 1px solid #ccc;
  border-radius: 5px;
}

.ck-content {
  height: 300px;

  overflow-y: scroll;
}

.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
  border-top: none;
}

.ck-content::-webkit-scrollbar {
  width: 8px;
  border-radius: 8px;
}

.ck-content::-webkit-scrollbar-thumb {
  background: #ecebeb;
  border-radius: 8px;
}

.ck-content::-webkit-scrollbar-thumb:hover {
  background: #c4c2c2;
}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
  border-radius: 8px 8px 0px 0px;
  background: #f5f5f5;
  height: 30px;
}

.ck.ck-balloon-panel.ck-powered-by-balloon .ck.ck-powered-by {
  display: none !important;
  margin: 5px 0 10px 35px;
}

/* .addstaff_accordion-content {
   border-bottom: 0.5px solid #CCC; 
  display: flex;
    justify-content: flex-end;
    align-items: center;

} */

.addstaff_secondCkeckbox_container {
  color: #333;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 5px 0 10px 35px;
}

.addstaff_secondCkeckbox {
  margin-right: 10px;
}

.selectboxicon {
  position: absolute;
  right: 38px;
  top: -36px;
}

.addstaff_accordion-content {
  position: relative;
}

.rotateicon {
  /* transform: rotate(180deg); */
  transition: all;
}

.permission_checkBox_selectall {
  margin: 0 10px;
}

.permission_checkBox_singlecheckbox {
  margin: 0 10px;
}

/* Fetched Image */
.featureImages_uplaodFile_container {
  width: 300px;
  height: 272px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #fff;
  border-radius: 8px;
  border: 0.5px solid #ccc;
  position: relative;
  padding: 0 10px;
}

.featureImages_upload_file_input {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  opacity: 0;
  z-index: 1;
}

.featureImages_uplaodFile_displaytext_container {
  width: 100%;
  height: 200px;
  border-radius: 4px;
  border: 0.5px dashed #888;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.featured_image_text {
  color: #3968ed;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 5px;
  border-radius: 4px;
  background: #ecf1fd;
  margin: 0;
}

.featured_image_text_dragdrop {
  color: #888;
  font-family: "Inter";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 5px 0 0 0;
}

  .featureImages_removeLogobtn {
    border: none;
    background-color: white;
  }

.featureImages_removeLogobtn:hover {
  cursor: pointer;
}

.featureImages_logoPreview_container,
.logoPreview_container {
  display: flex;
  margin-top: 5px;
  justify-content: space-between;
}

.multiple_image_upload_container {
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.multiple_image_upload_box {
  display: flex;
  width: 100%;
  margin-top: 5px;
  gap: 10px;
}

.multiple_image_upload_box img {
  object-fit: cover;
}

.featureImages_logoPreview_container img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.featureImages_addzindex {
  z-index: -1;
}

/* add stock hotlier */

.addStockContainer {
  width: 80%;
  border-radius: 8px;
  background: #fff;
  padding: 10px;
  margin: 10px auto;
}

.addStockHeading {
  color: #333;
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 20px;
}

.addStockCustomerDetails {
  display: flex;
  justify-content: space-between;
}

.addStockLabels {
  color: #333;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.addStaffInputs {
  width: 90%;
  height: 30px;
  border-radius: 4px;
  border: 0.5px solid #333;
  margin-top: 10px;
  margin-bottom: 20px;
}

.addStockTableHeader,
.addStockTableContent {
  display: flex;
  margin-top: 15px;
  gap: 10px;
}

.addStockTableContent {
  margin-top: 10px;
  margin-bottom: 6px;
}

.addStaffInputs[type="text"],
.addStaffInputs[type="number"],
.addStaffInputs[type="date"] {
  color: #333;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 5px;
}

.addStaffInputs[type="text"],
.addStaffInputs[type="number"],
.addStaffInputs[type="date"]:focus {
  outline: none;
}

.addStockTable {
  width: 100%;
  text-align: left;
  border-collapse: separate;
  border-spacing: 10px;
  padding: 0px;
}

.addStockButton {
  color: #3968ed;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: transparent;
  border: none;
  cursor: pointer;
}

.addStockButton>svg {
  vertical-align: middle;
  margin-right: 5px;
  margin-left: 5px;
}

.addStockTableItemName,
.addStockTablePrice,
.addStockTablePrice,
.addStockTableTotalPrice {
  height: 30px;
  padding: 5px;
  border-radius: 4px;
  border: 0.5px solid #333;
  padding: 5px;
}

.addStockTableItemName,
.addStockTablePrice,
.addStockTablePrice,
.addStockTableTotalPrice:focus {
  outline: none;
}

.addStockTableWeightSelect:focus {
  outline: none;
}

.addStockTableWeight:focus {
  outline: none;
}

.addStockTableItemName {
  width: 100%;
}

.addStockTablePrice {
  width: 100%;
}

.addStockTableWeight {
  border: none;
  width: 60%;
  padding: 5px;
  border-right: 0.5px solid #333;
}

.addStockTableWeightSelect {
  width: 40%;
  border: none;
  /* padding: 5px; */
  background: #eee;
}

.addStockTablePrice {
  width: 100%;
}

.addStockTableTotalPrice {
  width: 100%;
}

.addStockTableWeightContainer {
  display: flex;
  width: fit-content;
  border: 0.5px solid #333;
  border-radius: 4px;
  height: 100%;
  overflow: hidden;
  width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.addStockAddButton,
.addStockCancelButton {
  display: flex;
  height: 35px;
  padding: 10px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: none;
  color: white;
  font-family: "Inter";
  border-radius: 4px;
  background: #3968ed;
  cursor: pointer;
}

.addStockCancelButton {
  background-color: white;
  border: 1px solid #3968ed;
  color: #3968ed;
}

.addStockTotalPriceContainer {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.addStockSubTotal,
.addStockGrandTotal,
.addStockPaidAmount,
.addStockDueAmount {
  color: #333;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  width: fit-content;
}

.addStockSubTotal {
  margin: 0 0 0 auto;
}

.addStockGrandTotal,
.addStockPaidAmount {
  margin: 0 10px;
  width: fit-content;
}

/* REPORTS */

.report-chart-heading {
  color: #333;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}

/* Event slots */

.dynamic_event_slot_date {
  color: black;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 15px;
}

.horizontalLineEventSlots {
  background: red;
}

/* .custom_checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 125px;
  width: 25px;
  background-color: red;
} */

.custom_checkbox input[type="checkbox"] {
  width: 18px;
  height: 18px;
  border-radius: 4px;
  display: grid;
  place-content: center;
}

/* .custom_checkbox input[type="checkbox"] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 16px;
  height: 16px;
  border-radius: 10px;
  border: 0.15em solid rgba(171, 177, 186, 1);
  border-radius: 3px;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
} */

.custom_checkbox input[type="checkbox"]::before {
  content: "";
  width: 10px;
  height: 10px;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  /* box-shadow: inset 1em 1em black; */
}

.custom_checkbox input[type="checkbox"]:checked::before {
  transform: scale(1);
}

.banquet_menu_main {
  margin-bottom: 10px;
}

.banquet_menu_main .Polaris-LegacyCard {
  padding: 0 !important;
}

.banquet_menu_main .Polaris-LegacyCard .Polaris-LegacyCard__Section {
  padding: 0 !important;
}

.banquet_menu_main .Polaris-LegacyCard__Section .Polaris-LegacyStack .Polaris-LegacyStack__Item {
  margin: 0 !important;
}

.events_date_range_picker_comp {
  width: 100%;
  border: 0.5px solid #333;
  cursor: pointer;
  height: 36px;
}

.events_date_range_picker_comp .react-datepicker-wrapper {
  width: 100%;
  height: 100%;
}

.events_date_range_picker_comp .react-datepicker-wrapper .react-datepicker__input-container {
  height: 100%;
}

.events_date_range_picker_comp .react-datepicker-wrapper .react-datepicker__input-container input {
  width: 100%;
  border: none;
  height: 100%;
  margin: 0;
  padding: 10px;
  cursor: pointer;
}

.events_date_range_picker_comp .react-datepicker-wrapper .react-datepicker__input-container input:focus-visible {
  outline: none;
}

.communication_preview_voucher_popup_container {
  backdrop-filter: blur(5px);
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 11px -6px rgba(0, 0, 0, 0.75);
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  padding: 25px 0;
  transition: all 1s;
  width: 850px;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 650px;

  /* z-index: 10000000000000; */
}

.communication_iframe_container {
  backdrop-filter: blur(5px);
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 11px -6px rgba(0, 0, 0, 0.75);
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  padding: 25px 0;
  transition: all 1s;
  width: 750px;
  height: 80%;
  /* z-index: 10000000000000; */
  overflow: hidden;
}

.communication_iframe_container-2 {
  backdrop-filter: blur(5px);
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 11px -6px rgba(0, 0, 0, 0.75);
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  /* padding: 25px 0; */
  transition: all 1s;
  width: 100%;
  height: 100vh;
  /* z-index: 10000000000000; */
  overflow: hidden;
}


/* LOW INVENTORY */

.low_inventory_boxes {
  flex-basis: 10%;
  border-right: solid 1px #ececec;
  border-bottom: solid 1px #ececec;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  /* border: 1px solid #ececec; */
}

.room-div-box {
  background-color: rgb(255 255 255);
  padding: 16px;
  display: flex;
  font-weight: 500;
  border-bottom: 10px;
  border: 0.5px solid #ccc;
  border-bottom: 0;
}

.room-div-box.active {
  background-color: #3968ed;
  color: #fff;
  cursor: pointer;
}

.room-div-box:last-child {
  border-bottom: 0.5px solid #ccc;
}

.room-div-box:hover {
  background-color: #3968ed;
  color: #fff;
  cursor: pointer;
  transition: 0.3s ease-in all;
}

/* 7c9cf3 */

/*  */
.default-bg-flex-center {
  background: linear-gradient(11deg, #3968ed 0%, #7c9cf3 100%);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.webcheckin-navbar {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 70px;

  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

/* available */

.availableRoomsSelectBox {
  width: 80%;
  border: 0.0625rem solid rgb(136, 136, 136);
  border-radius: 0.25rem;
  padding: 5px;
  font-size: 14px;
  font-weight: 500;
}

.room-box {
  /* display: flex; */
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
  gap: 10px;
}

.availableRoomsSelectBoxContainer {
  position: relative;
}

.checkbox-container {
  /* position: absolute;
  top: 27px;
  left: 0; */
  background: #ffffff;
  z-index: 1;
  width: 80%;
  border: 0.0625rem solid rgb(136, 136, 136);
  border-top: 0 solid rgb(136, 136, 136);
  padding: 5px;
}

.tooltip-custom-input {
  width: 100%;
  min-height: 2.25rem;
  border: 1px solid rgb(171, 177, 186);
  border-radius: 3px;
  padding: 5px 12px;
  font-size: 15px;
}

.ck-content code {
  background-color: #f4f4f4;
  padding: 5px;
  border-radius: 3px;
}

.editor_container .ql-editor {
  min-height: 350px;
}

/* ADMIN INVENTORY  */

.low_inventory_boxes_admin {
  flex-basis: 14.285%;
  border-right: solid 1px #ececec;
  border-bottom: solid 1px #ececec;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 110px;
  gap: 1px;
}

.low_inventory_boxes_admin_squareBoxes {
  display: flex;
  width: 35px;
  height: 35px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
}

.low_inventory_boxes_admin_sold {
  margin-top: 3px;
  color: #666;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  /* 15.6px */
}

.Polaris-IndexTable__TableCell:has(> div.blue) {
  background-color: #ecf1fd !important;
  text-align: center;
}

.Polaris-IndexTable__TableCell:has(> div.green) {
  background-color: #dff8df !important;
  text-align: center;
}

.Polaris-IndexTable__TableCell:has(> div.red) {
  background-color: #ffe7ec !important;
  text-align: center;
}

.Polaris-IndexTable__TableCell:has(> div.yellow) {
  background-color: rgba(255, 249, 232, 1) !important;
  /* text-align: center; */
}

.Polaris-IndexTable__TableRow:is( :has(> .Polaris-IndexTable__TableCell > div.yellow)) {
  background-color: rgba(255, 249, 232, 1) !important;
  text-align: center;
}

.Polaris-IndexTable__TableCell:has(> div.currentDate) {
  background-color: #3968ed !important;
  text-align: center;
  color: #fff;
}

.meal_plan_with_background {
  color: black;
  border-radius: 4px;
  background: rgb(238, 238, 238);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  padding: 3px 6px;
  display: inline-flex;
}

.setting_location_sec .Polaris-LegacyCard {
  overflow-x: auto;
  overflow-y: scroll;
  cursor: pointer;
  max-height: 500px;
}

.setting_location_sec .Polaris-LegacyCard::-webkit-scrollbar {
  display: block;
  width: 5px;
}

.setting_location_sec .Polaris-LegacyCard::-webkit-scrollbar-track {
  background: rgb(204, 204, 204);
}

.setting_location_sec .Polaris-LegacyCard::-webkit-scrollbar-thumb {
  background-color: gray;
  border-right: none;
  border-left: none;
}

/* bill inputs */
.bill-inputs {
  height: 25px;
  width: 200px;
  padding: 2px;
  margin-top: 8px;
}

/* Addons */
/* .edit-rooms-form-container {
  width: fit-content;
  margin: 0 auto;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
} */

.edit-rooms-form-table-header {
  font-weight: 400;
  font-size: 14px;
  text-align: left;
}

.addEvent-input {
  padding: 5px 3px;
  border-radius: 4px;
  border: 0.5px solid #ccc;
  width: 100%;
  margin-right: 10px;
  margin-top: 5px;
}

.addEvent-input:focus {
  outline: none;
}

.edit-rooms-form-table-body {
  font-weight: 400;
  font-size: 14px;
  padding: 5px;
}

.edit-rooms-form-table-body-add-button {
  color: rgba(57, 104, 237, 1);
  background-color: transparent;
  border: none;
  font-family: "Inter";
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}

.edit-rooms-form-table-body-remove-button {
  color: rgba(224, 56, 56, 1);
  background-color: transparent;
  border: none;
  font-family: "Inter";
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}

.edit-rooms-form-table-body-remove-button:hover,
.edit-rooms-form-table-body-add-button:hover {
  text-decoration: underline;
}

.addEventTotalCostText {
  font-weight: 500;
  font-size: 14px;
  text-align: left;
  margin-top: 10px;
}

/* bookings */
.booking-popups-three-dots {
  background-color: #fff;
  border-radius: 2px;
  position: absolute;
  /* top: 35px; */
  right: 13px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  width: max-content;
  z-index: 10;
  border-radius: 6px;
  border: 0.5px #333 solid;
  border-bottom: 0;

  /* background-color: #fff;
  border-radius: 2px;
  position: absolute;
  top: 26px;
  right: -8px;
  right: 71px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  width: max-content;
  z-index: 10;
  border-radius: 6px;
  border: 0.5px #333 solid;
  border-bottom: 0; */
}

.agent_popup_btn svg {
  width: 15px;
  height: 15px;
}

.agent_popup_btn svg:hover {
  background-color: #ddd;
  cursor: pointer;
  border-radius: 50%;
  width: 15px;
  height: 15px;
}

.booking-popups-three-dots button {
  width: 100%;
  font-size: 12px;
  display: block;
  border: none;
  background-color: transparent;
  font-weight: 400;
  cursor: pointer;
  padding: 5px 10px;
  border-bottom: 1px #333 solid;
}

.room-view-popups-three-dots {
  background-color: #fff;
  border-radius: 2px;
  position: absolute;
  top: 35px;
  left: 0px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  width: max-content;
  z-index: 10;
  border-radius: 6px;
  border: 0.5px #333 solid;
  border-bottom: 0;
}

.room-view-popups-three-dots button {
  width: 100%;
  font-size: 12px;
  display: block;
  border: none;
  background-color: transparent;
  font-weight: 400;
  cursor: pointer;
  padding: 5px 10px;
  border-bottom: 1px #333 solid;
}

/* profile settings */

.profile_settings_amenities {
  font-size: 12px;
  padding: 5px;
  border-radius: 4px;
  width: fit-content;
  background-color: #ebebeb;
  font-family: "Inter";
}

.flex_gap_5 {
  gap: 5px;
}

/* global popup style */

.global_popup_container_background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.global_popup_container {
  width: 50%;
  height: fit-content;
  background: white;
  border-radius: 10px;
  padding: 20px;
}
.global_popup_container_heading{
  font-family: "Inter";
  font-weight: 600;
  font-size: 16px;

}

.user_profile_dropdown_container {
  position: relative;
}

.user_profile_dropdown_content {
  position: absolute;
  right: -15px;
  width: 150px;
  top: 50px;
  z-index: 20;
  box-shadow: "0 4px 4px 0px rgba(127, 155, 179, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.10)";
  background: white;
}

.user_profile_options_list li,
.user_profile_options_list a {
  color: #333;
  cursor: pointer;
}

.switch_property_popup_heading {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
}

.switch_property_popup_heading h1 {
  font-size: 20px;
  font-weight: bold;
}

/* communication css  */

.preview-btn {
  display: flex;
  justify-content: center;
}

.communication_subtotal_calc {
  display: flex;
  justify-content: space-between;
}

.communication_total_amt {
  font-weight: bold;
}

.communication_total_amt>div {
  color: #666666;
}

.reservation_total_leads {
  font-weight: bold;
  margin-right: 5px;
}

.fs-13 {
  font-size: 13px !important;
}

div.smsg_create_booking_page_top>div:not(:last-of-type) {
  background-color: red !important;
}

.b-none:first-child {
  border: none;
}

/* .p-0 {
  padding: 0 !important;
} */
/* .b-none:first-child {
  border-left: none !important;
} */

.communication-scrollable-container {
  max-height: 100px;
  overflow-y: auto;
}

.communication-scrollable-container::-webkit-scrollbar {
  display: none;
}

.communication-custom-padding {
  padding: 0 !important;
  margin: 10px 0px;

}

.communication-custom-position {
  position: static;
}

.customTableCheckbox {
  position: relative;
  border: 1px solid #ABB1BA;
  border-radius: 3px;
  background: none;
  cursor: pointer;
  line-height: 0;
  margin: 2px 8px 0 0;
  outline: 0;
  padding: 0 !important;
  vertical-align: text-top;
  height: 13px;
  width: 13px;
}










.reports-new-background {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
}

.quote-imgs-thumbs {
  background: #F1F2F4;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  margin: 1.5rem 0;
  padding: 1rem 1rem 1rem 2rem;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: start;
  gap: 1rem;
}

.quote-imgs-thumbs--hidden {
  display: none;
}

.img-preview-thumb {
  background: #fff;
  border: 1px solid #777;
  border-radius: 0.25rem;
  box-shadow: 0.125rem 0.125rem 0.0625rem rgba(0, 0, 0, 0.12);
  max-width: 150px;
  padding: 0.10rem;
  /* Adjusted padding */
  box-sizing: border-box;
  transform: scale(0.8);
  /* Initially smaller size */

  /* Transition properties */
  transition: opacity 0.3s ease, transform 0.3s ease;

}
















@media screen and (max-width: 1400px) {
  .footer_nav_bar_menus .footer_nav_bar_item .item_list {
    padding: 0;
  }
}

.filtered_countries_list,
.filtered_states_list {
  list-style: none;
  overflow: auto;
  max-height: 150px;
  padding: 0px 5px;
  line-height: 26px;
  box-shadow: 0 4px 4px 0px rgba(127, 155, 179, 0.1),
    0 6px 20px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 30;
  right: 0;
  left: 0;
  background: white;
}

.filtered_countries_list li,
.filtered_states_list li {
  cursor: pointer;
}

.wrapper-div-city-ledger {
  display: flex;
  gap: 10px;
}

.outer-div-city-ledger {
  display: flex;
  gap: 10px;
}

.pageFooter {
  background-color: #fff;
}

/* css done */
@media screen and (max-width: 1400px) {
  .wrapper-div-city-ledger {
    flex-direction: column;
  }

  .role_based_menus_navbar,
  .reservation_bar_container {
    gap: 5px !important;
  }

  .footer_nav {
    width: 50% !important;
  }

  .custom_tab_container .custom_tab_header {
    justify-content: space-between;
  }

  .custom_tab_container .custom_tab_header .tab_item {
    margin-right: 13px;
  }

  .create_booking_page_top div:not(:last-of-type) {
    padding: 0px !important;
  }

  .nightAudit_popup_section {
    width: 850px !important;
  }

  .booking_search_content input {
    width: 110px;
  }

  .booking_search_content input::placeholder {
    font-size: 10px !important;
  }

  .booking_search_content svg {
    margin: 0px 4px;
  }

  .reservation_total {
    padding: 0px 5px;
  }

  .reservation_total_paragraph {
    font-size: 11px;
  }

  .reservation_total_heading {
    font-size: 14px;
  }

  .communication_iframe_container {
    width: 920px;
    padding: 30px;
  }
}

/* scroll bar styling */
.overflowContainer::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.overflowContainer::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
  border-left: 1px solid #ddd;
}

.overflowContainer::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.overflowContainer::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* date range picker popup */
.popup_options_item {
  padding: 5px 10px;
  border-radius: 4px;
  font-weight: 400;
  font-size: 13px;
}

.popup_options_item:hover {
  background-color: #3968ed;
  color: white;
  cursor: pointer;
}

.options-popup {
  position: absolute;
  /* top: 100%; */
  /* padding: 43px 0px 20px; */
  left: 0;
  background-color: #fff;
  z-index: 999;
  box-shadow: 0 4px 4px 0px rgba(127, 155, 179, 0.1),
    0 6px 20px 0 rgba(0, 0, 0, 0.1);
  width: auto;
}

.date_range_input {
  font-size: 12px;
  width: max-content;
}

.date-picker-animation {
  position: absolute;
  top: 44px;
  left: 100%;
  /* padding: 5px 10px; */
  transition: left 0.9s ease;
  /* height: 88%; */
  height: fit-content;
  width: fit-content;
  /* background: white; */
  box-shadow: 0 0px 0px 0 rgba(127, 155, 179, 0.1),
    4px 0 4px 0 rgba(127, 155, 179, 0.1);
  /* box-shadow: 0 4px 4px 0px rgba(127, 155, 179, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.10); */
}

.date-picker-animation .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day {
  font-size: 12px;
  width: 1.5rem;
  line-height: 1.5rem;
  margin: 0.16rem;
}

.date-picker-animation .react-datepicker .react-datepicker__month-container .react-datepicker__day-names,
.react-datepicker__day-name {
  font-size: 12px;
}

/* button with loader inside */


.inventory_parent_container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 50px;
  border: 1px solid #ddd;
}

.inventory_input {
  width: 60px;
  outline: none;
  height: 35px;
  border: 1px solid #ddd;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
}

@media screen and (max-width: 1200px) {
  .inventory_parent_container {
    width: 71px !important;
  }
}

.Toastify{
  position: relative;
  z-index: 9999999999;
}
.link{
  font-size: 14px;
  color: #3968ed;



}