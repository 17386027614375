

.carouselContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: auto;
  position: relative;
  .carouselButton{
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 5px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    &:first-of-type {
      left: 0;
    }
    &:last-of-type {
      right: 0;
    }
  }
  .imageContainer{
    


    img{
      animation: fadeIn 1s ease-in-out;
    }
  }

 

}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
