.dialogOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.dialogBox {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.3);
  width: 80%;
  max-width: 500px;
}

.messageBox {
  text-align: center;
  &_success {
    text-align: center;
    border-bottom: solid 4px #28a745;

    i {
      font-size: 55px;
      color: #28a745;
    }

    h2 {
      margin-bottom: 12px;
      font-size: 40px;
      font-weight: 500;
      line-height: 1.2;
      margin-top: 10px;
    }

    p {
      margin-bottom: 0px;
      font-size: 18px;
      color: #495057;
      font-weight: 500;
    }
  }
}