.customRoomChoiceSelectTogglerContainer {
    border: 0.5px solid #ccc;
    width: fit-content;
    font-size: 14px;
    font-family: "Inter";
    color: #1F2124;
    padding: 7px 10px;
    border-radius: 4px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  .customRoomChoiceSelectTogglerIcon {
    vertical-align: middle;
  }
  .customRoomChoiceSelectOption {
    width: 100%;
    position: absolute;
    border: 0.5px solid #ccc;
    padding: 5px 10px;
    border-radius: 4px;
    top: 35px;
    z-index: 16;
    background: white;
  }
  .customRoomChoiceSelectContainer {
    position: relative;
  }
  .customRoomChoiceSelectLabel {
    display: flex;
    font-size: 14px;
    font-family: "Inter";
    color: #666;
    cursor: pointer;
  }
  .customRoomChoiceSelectIconRotate {
    transform: rotate(180deg);
    transition: transform 0.3s;
  }
  .customRoomChoiceSelectIcon {
    transform: rotate(0deg);
    transition: transform 0.3s;
  }
  .customRoomChoiceSelectHeading {
    font-size: 14px;
    margin-bottom: 5px;
    padding: 0;
    color: rgba(31, 33, 36, 1);
    font-family: "Inter";
  }
  .customRoomChoiceSelectCheckbox {
    position: relative;
    border: 2px solid #ABB1BA;
    border-radius: 5px;
    background: none;
    cursor: pointer;
    line-height: 0;
    margin: 2px 8px 0 0;
    outline: 0;
    padding: 0 !important;
    vertical-align: text-top;
    height: 18px;
    width: 18px;
    /* -webkit-appearance: none; */
    appearance: none;
    opacity: 1;
  }
  .customRoomChoiceSelectCheckbox:hover {
    opacity: 1;
  }
  .customRoomChoiceSelectCheckbox:checked {
    background-color: #2463BC;
    opacity: 1;
    border: 2px solid #2463BC;
  }
  .customRoomChoiceSelectCheckbox:before {
    content: "";
    position: absolute;
    right: 50%;
    top: 50%;
    width: 3px;
    height: 6px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    margin: -1px -1px -1px -1px;
    transform: rotate(45deg) translate(-50%, -50%);
    z-index: 2;
  }
  .customRoomChoiceSelectButtonContainer {
    display: flex;
    justify-content: space-between;
    padding: 5px 0 10px 0;
  }
  .customRoomChoiceSelectButton {
    border: none;
    background-color: transparent;
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  }
  
  
  
  
  
  
  
  
  
  
  