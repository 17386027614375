.agent_hold_rooms_popup_select:focus {
    /* border: 1px solid #5682fa; */
    outline: none; /* Optional, if you want to remove the default focus outline */
  }

  .create-contract-container{
    margin: 90px auto 0;
  }

  .create-contract-heading{
    color: "#333";
    font-family: "Inter";
    font-size: "18px";
    font-weight: "600";
  }