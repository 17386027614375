@import "../settings//globalMixins";
.sliderComponentSkeletonContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(3px);
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 1100000;
  display: flex;
  justify-content: flex-end;


  .sliderHeader {
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    button {
      background-color: transparent;
      height: fit-content;
      width: fit-content;
      border: none;
      cursor: pointer;
    }
    hr {
      border: 0;
      border-top: 0.5px solid #ccc;
    }
    .sliderTitle {
      font-weight: 500;
      font-size: 15px;
      font-family: "Inter";
    }
  }
}
.sliderContent{
  height: 95%;
  overflow: auto;
  @include scrollbar-styling
}

.sliderComponentSkeletonChild {
  background-color: #fff;
  width: 0; 
  height: 100%;
  position: fixed;
  top: 0;
  right: 0; 
  z-index: 1100001;
  box-shadow: -17px 0px 7px -12px rgba(0, 0, 0, 0.2);
  border-radius: 8px 0 0 8px;
  overflow: hidden; 
  transition: width 0.6s ease-in-out, opacity 0.6s ease-in-out; 
  opacity: 0; 

}


.sliderComponentSkeletonChild.open {
  animation: slideIn 0.6s forwards ease-in-out; 
}

@keyframes slideIn {
  from {
    width: 0;
    opacity: 0;
  }
  to {
    width: 50%; /* Final width for the slider */
    opacity: 1;
  }
}


.sliderComponentSkeletonChild.close {
  width: 0; 
  opacity: 0; 
}