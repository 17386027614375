@mixin scrollbar-styling {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
    border: 0.4px solid #ccc;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #c6c6c6;
    border-radius: 8px;
    margin: 2px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #8f8b8b;
  }

  &::-webkit-scrollbar-corner {
    background: #fff;
  }
}

@mixin label-styling {
  color: #333;
  white-space: nowrap;

  &.large {
    font-size: 16px;
    margin-bottom: 5px;
  }

  &.medium {
    font-size: 14px;
    margin-bottom: 3.5px;
  }

  &.small {
    font-size: 12px;
    margin-bottom: 2.5px;
  }
}
