.agentSidebarContentContainer {
  padding: 10px 15px;
}
.agentHoldRoomsSidebarHeadingSmall {
  color: #333;
  font-family: " Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}


.agentHoldBookingSliderContractInsight {
  border-radius: 8px;
  border: 0.5px solid #ccc;
  padding: 5px;
}
.agentHoldRoomsPopupReleaseType {
  color: #3968ed;
  font-family: "Inter";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.agentHoldRoomsPopupReleaseTypeContainer {
  padding: 5px;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  background: #ecf1fd;
}
.agentHoldBookingSliderContractInsightSelect {
  height: 28px;
  outline: none;
  width: 100%;
  border: 1px solid #aaaaaa;
  border-radius: 4px;
}
.agentHoldBookingSliderContractInsightSelect :focus {
  outline: none;
}
