.mobile_nav_button_toggle {
  background-color: #3968ed;
  color: #fff;
  position: relative;
  text-align: center;
  cursor: pointer;
  padding: 10px;
}
.mobileNavigationKotOptionsButton {
  color: #fff;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background-color: #3968ed;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
}
.mobileNavigationKotOptions {
  position: absolute;
  top: 30px;
  background-color: #3968ed;
  width: 100%;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
  align-items: center;
  border-radius: 0 0 4px 4px;
  left: 0;
}
