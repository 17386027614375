.select_property_card {
  border-radius: 15px;
  height: 250px;
  width: 250px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  a{
    text-decoration: none;
    color: black;
  }
  .select_property_image {
    height: 80%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #dddddd;
    div {
      height: 80%;
      width: 80%;
      img {
        object-fit: contain;
      }
    }
  }

  .select_property_name {
    height: 20%;
    width: 100%;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    border-top: 1px solid #e5e5e5;
    color: #333;
  }
}
