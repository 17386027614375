@import "../settings/globalMixins";
.tabsMain {

    padding: 10px;
    position: sticky;
    background-color: #ffffff;


    .tabsWrapper {
        
        display: flex;
        align-items: center;
        gap: 15px;
        overflow-x: auto;
        overflow-y: hidden;

        @include scrollbar-styling;
        .tabsCards {
            // padding: 8px 12px;
            // margin: 0px 10px;

            .btn_icon_de{
                svg{

                    fill: #333333;
                }
            }
            .btn_icon_active{
                svg{
                    fill: #FFFFFF;
                }
            }
            .btn_icon_underline{
                svg{
                    fill: #3968ED;
                }
            }

            a {
                color: #333333;
                padding: 8px 12px;
                text-wrap: nowrap;
                white-space: nowrap;
                margin: 2px 0;
                

                // .btn_icon_de{
                //     svg{

                //         fill: #333333;
                //     }
                // }
            }

        }
        .underlineActive,.underHover{
            display: flex;
            gap: 5px;
        }

        .underline {

            // transition: all 0.2s cubic-bezier(0.46, 0.02, 0, 1.01);

            .underHover {
                // display: inline-block;
                text-decoration: none;
                position: relative;
                // transition: all 0.2s cubic-bezier(0.46, 0.02, 0, 1.01);

                &::after {
                    content: "";
                    position: absolute;
                    bottom: -2px;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background-color: #3968ED;
                    transform: scaleX(0);
                    transform-origin: bottom left;
                    transition: transform 0.3s cubic-bezier(0.46, 0.02, 0, 1.01);
                }

                &:hover::after {
                    transform: scaleX(1);
                }

            }
        }

        .underlineActive {
            color: #3968ED !important;
            border-bottom: 2px solid #3968ED;
        }

        .solidBackground {

            border-radius: 5px;
            // padding: 8px 12px;

            a {
                display: flex;
                align-items: center;
                gap: 10px;
                border-radius: 5px;
                transition: all 0.4s cubic-bezier(0.46, 0.02, 0, 1.01);

                &:hover {
                    background-color: #F0F4FD;
                }
            }


        }

        .solidActive {
            background-color: #3968ED;
            color: #ffffff !important;

            &:hover {
                background-color: #3968ED !important;
            }
        }
    }
}