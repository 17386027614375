.AgentHoldRoomsUpliftMenuButtonContainer {
  position: relative;
  display: inline-block;
}

.AgentHoldRoomsUpliftMenuButton-popup-menu-list {
  position: absolute;
  bottom: 110%;
  left: 50%;
  transform: translateX(-50%) translateY(-10px);
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: white;
  border: 1px solid #ccc;
  width: max-content;
  border-radius: 4px;
  box-shadow: inset 1px 1px 2px 0px #ddd, 2px 2px 4px 1px #fff;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s, transform 0.6s ease-in-out;
  z-index: 100;
}

.AgentHoldRoomsUpliftMenuButton-popup-menu-list.visible {
  opacity: 1;
  transform: translateX(-50%) translateY(0);
  pointer-events: auto;
}

.AgentHoldRooms-popup-menu-item {
  padding: 5px 10px;
  cursor: pointer;
  border-bottom: 0.5px solid #ddd;
}

.AgentHoldRooms-popup-menu-item:hover {
  background-color: #f1f1f1;
}