.Create_booking_label{
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    cursor: pointer;
}

.Create_booking_selectBox {
    border: 1px solid #ddd;
    width: 135px;
    outline: none;
    cursor: pointer;
    border-radius: 4px;
    padding: 5px;
    justify-content: space-between;
  }
  .Create_booking_selectBox input {
    border: 0;
    outline: none;
    width: 90px;
  }
  @keyframes border-animation {
    0% {
      opacity: .8; /* Start with border hidden */
    }
    50% {
      opacity: 1; /* Fade in border */
    }
    100% {
      opacity: .7; /* Fade out border */
    }
  }
  
  .create_booking_error-border {
    animation: border-animation 1s ease infinite; /* Apply animation to border */
  }
 