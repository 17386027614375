@mixin scrollbar-styling {
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
      border: .4px solid #ccc;
    }
  
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border: .4px solid #ccc;
    }
  
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 6px;
    }
  
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  
    &::-webkit-scrollbar-corner {
      background: #f1f1f1;
    }
  }