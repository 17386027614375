@import "../settings/_globalMixins";
.custom_date_range_picker_new_design_system_container {
  .label{
    @include label-styling;

  }

  .date_picker_container {
    display: flex;
    align-items: center;
    border: 1px solid #ADADAD;
    background-color: #fff;
    gap: 5px;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
    width: 100%;
    

    &.small {
      height: 23px;
      padding: 3px 5px;
      font-size: 12px;
    }
    &.medium {
      height: 29px;
      font-size: 12px;
      padding: 4px 5px;
    }
    &.large {
      height: 35px;
      font-size: 14px;
      padding: 4px 5px;
    }
  }
}

.custom_date_range_picker_new_design_system {
  position: absolute;
  top: 110%;
}
