.Create_booking_label{
    font-size: 12px;
    font-weight: 400;
    color: #333333;
    cursor: pointer;
}
.flex{
    display: flex;
}
.gap_7{
    gap: 7px;
}
.common_min_input,.editable_input_small{
    border: 1px solid #ddd;
    width: 80px;
    border-radius: 4px;
    padding: 5px 2px;
    background-color: #EEEEEE;
    justify-content: space-between;
  }
  .editable_input_small{
    width: 50px !important;
  }
  .editable_input_small input{
    width: 23px !important;
  }
  .common_min_input input,.editable_input_small input{
    border: 0;
    width: 55px;
    outline: none;
    background-color: transparent;
  }
  
  .create_booking_editable_input_popup{
    position: absolute;
    background-color: white;
    box-shadow: 0 4px 4px 0px rgba(127, 155, 179, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.10);
    padding: 10px;
  }