h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Inter";
}

.common_title h2 {
    color: #333;
    font-family: "Inter";
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.common_title h3 {
    color: #333;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.p_top_0 {
    padding-top: 0px;
}
.p_10{
    padding: 10px;
}

.p_top_bottom_20 {
    padding: 20px 0;
}

.m_0_auto {
    margin: 0 auto;
}
.pointer {
    cursor: pointer;
}

.flex {
    display: flex;
}

.flex_direction_col {
    flex-direction: column;
}

.align_items_center {
    align-items: center;
}

.mx-10{
    margin: 0 10px;
}
.mx-20{
    margin: 0 20px;
}

.mt-20 {
    margin-top: 20px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-10 {
    margin-bottom: 10px;
}

.w-full {
    width: 100%;
}
.w-half {
    width: 50%;
} 

.w-70 {
    width: 70%;
}
.m-10 {
    margin: 10px 0;
}

.w-30 {
    width: 30%;
}

.justify_content_center {
    justify-content: center;
}

.justify_content_end {
    justify-content: flex-end;
}

.justify_content_between {
    justify-content: space-between;
}
.text_center{
    text-align: center;
}


.flex_gap_5 {
    gap: 5px;
}

.flex_gap_15 {
    gap: 15px;
}

.flex_gap_10 {
    gap: 10px;
}

.flex_gap_20 {
    gap: 20px;
}

.flex_wrap {
    flex-wrap: wrap;
}

.icon_button_only {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.overflow_x_scroll_y_hidden {
    overflow-x: auto;
    overflow-y: hidden;
    cursor: pointer;
}

.overflow_y_scroll_x_hidden {
    overflow-x: hidden;
    overflow-y: auto;
    /* cursor: pointer; */
}

.overflow_x_scroll_y_hidden::-webkit-scrollbar {
    display: block;
    height: 5px;
}

.overflow_x_scroll_y_hidden::-webkit-scrollbar-track {
    background: rgb(204, 204, 204);
}

.overflow_x_scroll_y_hidden::-webkit-scrollbar-thumb {
    background-color: gray;
    border-right: none;
    border-left: none;
}



.overflow_y_scroll_x_hidden::-webkit-scrollbar {
    display: block;
    width: 5px;
}

.overflow_y_scroll_x_hidden::-webkit-scrollbar-track {
    background: rgb(204, 204, 204);
}

.overflow_y_scroll_x_hidden::-webkit-scrollbar-thumb {
    background-color: gray;
    border-right: none;
    border-left: none;
}

/* .settlement_div_main div {
    padding: 0 10px;
} */