.customSearchInput {
    border: none;
    outline: none;
    width: 100%;

}

.customSearchInputContainer {
    border: 1px solid #aaa6a6;
    border-radius: 4px;
    padding: 7px;
}

.custom-search-input-container-focused {
    outline: 3px solid #5682fa;
    border-radius: 4px;

}

.customSearchInputContainerParent {
    border-radius: 4px;
    padding: 1px;
}

.customSearchSuggestionList {

    cursor: pointer;
}

.customSearchSuggestionList:hover {
    background-color: #e6e6e6;

}
.customSearchSuggestionListHover{
    background-color: #e6e6e6;
    border-radius: 4px;
}

.searchSuggestionButton {
    background-color: transparent;
    border: none;
    outline: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
    padding: 5px;

}

.searchSuggestionButtonDisabled {
    cursor: not-allowed;
}

.customSearchLabelName {
    margin-bottom: 4px;
}

.customSearchSuggestion {

    position: absolute;
    top: 110%;
    left: 0;
    width: 100%;
    background-color: white;

    max-height: 200px;
    list-style: none;
    overflow-y: auto;
    border-radius: 4px;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, .1);
    z-index: 1000;
}